import { createContext } from 'react';
import { ApiStrategy, ThemeEngineStrategy } from '@customer-portal/affinity-api';

import { Authentication } from '../hooks/useAuthentication';

type RechargeContext = {
  auth: Authentication;
  apiStrategy: ApiStrategy;
};

export const RechargeContext = createContext<RechargeContext>({
  auth: { token: '', hash: '' },
  apiStrategy: ThemeEngineStrategy(''),
});

RechargeContext.displayName = 'RechargeContext';
