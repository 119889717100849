/* eslint-disable no-empty-pattern */
import { Box } from '../Box';
import SpinnerIcon from '../Iconography/spinner.svg';
import * as styles from './Spinner.css';

// eslint-disable-next-line @typescript-eslint/ban-types
export type Spinner = {};

export const Spinner = ({}: Spinner) => {
  return (
    <Box className={styles.root}>
      <Box className={styles.icon}>
        <SpinnerIcon />
      </Box>
    </Box>
  );
};
