import { Children, ReactNode } from 'react';
import { Box } from '../Box';
import { ResponsiveValue } from '../Box/sprinkles.css';
import { vars } from '../theme.css';
import { Align, alignToFlexAlign, AlignY, alignYToFlexAlign } from '../utils/align';

export type Inline = {
  component?: 'div' | 'ol' | 'ul';
  children: ReactNode;
  align?: Align;
  alignY?: AlignY;
  reverse?: boolean;
  wrap?: Box['flexWrap'];
  space?: ResponsiveValue<keyof typeof vars.size>;
  container?: 'auto' | 'none';
};

export const Inline = ({
  space = 'medium',
  align,
  alignY,
  reverse,
  children,
  component = 'div',
  wrap = 'wrap',
  container = 'auto',
}: Inline) => {
  const isList = component === 'ol' || component === 'ul';
  const inlineItemComponent = isList ? 'li' : 'div';
  const justifyContent = alignToFlexAlign(align);
  const alignItems = alignYToFlexAlign(alignY);

  return (
    <Box
      flexWrap={wrap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      as={component}
      display="flex"
      flexDirection={reverse ? 'row-reverse' : 'row'}
      gap={space}
    >
      {Children.map(children, child => {
        if (container === 'auto' && (child || child === 0)) {
          return <Box as={inlineItemComponent}>{child}</Box>;
        }

        if (container === 'none') {
          return child;
        }

        return null;
      })}
    </Box>
  );
};

export const InlineItem = ({
  shrink = 1,
  grow = 0,
  basis = 'auto',
  children,
}: {
  shrink?: Box['flexShrink'];
  grow?: Box['flexGrow'];
  basis?: Box['flexBasis'];
  children: Box['children'];
}) => {
  return (
    <Box flexShrink={shrink} flexGrow={grow} flexBasis={basis}>
      {children}
    </Box>
  );
};
