import { PressEvent } from '@react-types/shared';
import { ButtonHTMLAttributes, forwardRef, ReactNode, useRef } from 'react';
import { FocusRing, useButton } from 'react-aria';
import { Box } from '../Box';
import { Inline } from '../Inline';
import { Spinner } from '../Spinner';
import { Focus } from '../utils/focus.css';
import type { TestAttributes } from '../types';
import * as styles from './Button.css';

export type ButtonProps = styles.ButtonVariants &
  TestAttributes &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    fill?: Box['width'];
    href?: string;
    children: JSX.Element | ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    onPress?: (e: PressEvent) => void;
  };

export const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      href,
      children,
      disabled,
      isLoading = false,
      type = 'button',
      fill = 'auto',
      size = 'large',
      tone = 'neutral',
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    const elementType = href ? 'a' : variant === 'tertiary' ? 'span' : 'button';

    const innerRef = useRef(null);

    const { buttonProps } = useButton(
      {
        isDisabled: isLoading || disabled,
        elementType,
        'aria-label': props['aria-label'],
        onPress: props.onPress,
      },
      innerRef
    );

    return (
      <Box display="flex" flexWrap="nowrap">
        <FocusRing focusRingClass={Focus}>
          <Box
            {...buttonProps}
            aria-current={props['aria-current']}
            aria-label={buttonProps['aria-label']}
            ref={ref}
            as={elementType}
            href={href}
            width={fill}
            type={type}
            alignItems="center"
            justifyContent="center"
            borderRadius="xxsmall"
            overflow="hidden"
            position="relative"
            className={`recharge-button recharge-button-${variant} ${styles.button({ tone, variant, size })}`}
            data-testid={props['data-testid']}
          >
            <Box className={styles.text({ isLoading })}>
              <Inline space="small" align="center" alignY="center">
                {children}
              </Inline>
            </Box>

            {isLoading && (
              <Inline space="small" align="center" alignY="center">
                <Box className={styles.spinner({ isLoading })}>
                  <Spinner />
                </Box>
              </Inline>
            )}
          </Box>
        </FocusRing>
      </Box>
    );
  }
);

Button.displayName = 'Button';
