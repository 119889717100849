import { RequestWrapperResponse } from '~/types/http';

export class HttpResponseError extends Error {
  body: string;
  status: number;
  json: any;

  constructor(message: string, status: number, body: string) {
    super(message);
    this.body = body;
    this.status = status;
    this.name = 'HttpResponseError';
    this.json = JSON.parse(body);
  }
}

export async function handleJsonResponse<T extends object>(response: RequestWrapperResponse): Promise<T> {
  if (!response.ok && !response.redirected) {
    throw new HttpResponseError(`${response.status}: An error occurred`, response.status, await response.text());
  }

  // We want to get the text and instead parse to JSON. This will prevent empty responses from raising an exception
  const text = await response.text();
  if (response.redirected || text === '') {
    return {} as T;
  }

  return JSON.parse(text);
}
