import { PressEvent } from '@react-types/shared';
import { ButtonHTMLAttributes, forwardRef, ReactNode, useRef } from 'react';
import { FocusRing, useButton } from 'react-aria';
import { Box } from '../Box';
import { Inline } from '../Inline';
import { Text } from '../Text';
import { Focus } from '../utils/focus.css';
import type { TestAttributes } from '../types';
import * as styles from './ActionLink.css';

export type Button = styles.ActionLinkVariants &
  TestAttributes &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    href?: string;
    fill?: Box['width'];
    children: JSX.Element | ReactNode;
    disabled?: boolean;
    onPress?: (e: PressEvent) => void;
  };

export const ActionLink = forwardRef<HTMLElement, Button>(
  ({ href, children, disabled, fill = 'auto', tone = 'neutral', ...props }, ref) => {
    const elementType = href ? 'a' : 'span';

    const innerRef = useRef(null);

    const { buttonProps } = useButton(
      {
        isDisabled: disabled,
        elementType,
        'aria-label': props['aria-label'],
        onPress: props.onPress,
      },
      innerRef
    );

    return (
      <FocusRing focusRingClass={Focus}>
        <Box
          {...buttonProps}
          aria-current={props['aria-current']}
          aria-label={buttonProps['aria-label']}
          ref={ref}
          as={elementType}
          href={href}
          display="inline-flex"
          width={fill}
          alignItems="center"
          justifyContent="center"
          borderRadius="xxsmall"
          className={`recharge-action-link ${styles.root({ tone })}`}
          data-testid={props['data-testid']}
        >
          <Text variant="p3" as="span">
            <Inline space="small" align="center" alignY="center">
              {children}
            </Inline>
          </Text>
        </Box>
      </FocusRing>
    );
  }
);

ActionLink.displayName = 'ActionLink';
