import { Product } from '@customer-portal/affinity-api';

export const discountByPercentage = (price: number, percentage: number) => price - (percentage / 100) * price;

export const discountByFixedAmount = (price: number, amount: number) => price - amount;

export const calculateDiscount = (price: number, discount: number, discountType: Product['discount_type']) => {
  if (discountType === 'percentage') {
    return discountByPercentage(price, discount);
  }

  if (discountType === 'fixed_amount') {
    return discountByFixedAmount(price, discount);
  }

  return price;
};
