import { ReactNode } from 'react';
import { Box } from '../Box';
import * as styles from './Heading.css';

export type Heading = styles.HeadingVariants & {
  children: ReactNode;
  as?: Box['as'];
};

export const Heading = ({ children, variant, tone = 'primary', as = 'span', ...props }: Heading) => {
  /**
   * to indicate to assistive technologies that the `Heading` element should be treated as a Heading (h1-h6).
   *
   * Defaults to `1` because that's what we use in styles' defaultVariant
   *
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role#description
   */
  const headingLevel = Number(variant?.split('h')?.[1] ?? '1');

  return (
    <Box
      as={as}
      className={`recharge-heading recharge-heading-${variant} ${styles.root({ variant, tone })}`}
      role="heading"
      aria-level={headingLevel}
      {...props}
    >
      {children}
    </Box>
  );
};
