import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const PRODUCTS_QUERY_KEY = 'products';

type Params = object;

export const useGetProducts = (params: Params = {}, options = { enabled: true }) => {
  const { api } = useApi();

  return useQuery([PRODUCTS_QUERY_KEY, params], () => api.getProducts(params), { ...options, staleTime: Infinity });
};

export const useInfinityGetProducts = (params: Params = {}, options: { enabled?: boolean } = { enabled: true }) => {
  const { api } = useApi();

  return useInfiniteQuery(
    [PRODUCTS_QUERY_KEY, params],
    ({ pageParam }) =>
      api.getProducts({
        ...params,
        page: pageParam,
      }),
    {
      getNextPageParam: lastPage => lastPage.next?.page,
      enabled: options.enabled,
      staleTime: Infinity,
    }
  );
};
