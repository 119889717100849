import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

import { post } from '~/common/rest';
import { Experience } from '~/types/api/response/experiences';

export async function claimExperience(
  context: ApiContext,
  payload: Payloads.ClaimExperiencePayload
): Promise<Experience> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/experiences/offer/claim`, {
    use_flow: '1',
  });
  const response = await post<Experience>(endpointUrl, payload);
  return response;
}
