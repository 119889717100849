import { get } from '~/common/rest';
import { ThemeEngineExpireExperiencesPayload } from '~/types/api/response/experiences';
import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

export async function getExperiences(context: ApiContext, filters: Payloads.GetExperiencePayload = {}) {
  const schema = { experiences: filters };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const { experiences } = await get<ThemeEngineExpireExperiencesPayload>(endpointUrl);

  return {
    experiences: experiences.length ? experiences : [],
  };
}
