import type { RequestWrapper, RequestWrapperOptions, RequestWrapperResponse, RequestWrapperUrl } from '~/types/http';
// @ts-expect-error The package below is not a module
import { fetch as fetchPolyfill } from 'whatwg-fetch';

interface RequestConfig {
  fetchFn: RequestWrapper;
}

function normalizeOptions(options: Partial<RequestConfig>): RequestConfig {
  return { fetchFn: options.fetchFn || fetchPolyfill };
}

function generateHeaders(options: Partial<RequestWrapperOptions>): Record<string, string> {
  const jsonableMethods = ['POST', 'PUT', 'PATCH'];
  const requestHeaders: Record<string, string> = {
    Accept: 'application/json',
    'Cache-Control': 'max-age=30, public',
    ...options.headers,
  };

  if (jsonableMethods.includes(options.method || 'GET')) {
    requestHeaders['Content-Type'] = 'application/json';
  }

  return requestHeaders;
}

export async function makeJsonRequest(
  url: RequestWrapperUrl,
  options: Partial<RequestWrapperOptions>,
  _config: Partial<RequestConfig> = {}
): Promise<RequestWrapperResponse> {
  const config = normalizeOptions(_config);

  return config.fetchFn(url, { ...options, headers: generateHeaders(options) });
}
