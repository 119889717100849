import { AriaAttributes } from 'react';
import { Box } from '../Box';
import * as styles from './Text.css';

export type Text = styles.TextVariants & AriaAttributes & Pick<Box, 'as' | 'children'>;

export const Text = ({ children, variant, tone, decoration, as = 'span', ...props }: Text) => {
  return (
    <Box
      {...props}
      as={as}
      className={`recharge-text recharge-text-${variant} ${styles.root({ variant, tone, decoration })}`}
    >
      {children}
    </Box>
  );
};
