import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineSubscription, SubscriptionThemeEngineResponse } from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';

type NextChargeDateParams = {
  pause?: 'true';
};

export async function setSubscriptionNextChargeDate(
  context: ApiContext,
  { id, payload }: Payloads.ChangeNextChargeDateSubscriptionPayload
): Promise<ThemeEngineSubscription> {
  const { pause, ...updatePayload } = payload;

  const params: NextChargeDateParams = {};
  if (pause) {
    params.pause = 'true';
  }

  const endpointUrl = context.urlGenerator(
    context.baseUrl,
    context.token,
    `/subscriptions/${id}/set_next_charge_date`,
    params
  );

  const response = await post<SubscriptionThemeEngineResponse>(endpointUrl, updatePayload);
  return response.subscription;
}
