import type { ApiContext } from '~/types/strategies';
import type * as Payloads from '~/types/requests';
import type IsoDateString from '~/types/generic/isoDateString';
import type { AddressThemeEngineResponse } from '~/types/api/response/addresses';

import { post } from '~/common/rest';

interface ThemeEngineMergeAddressesAddressData {
  id: string;
}

interface ThemeEngineMergeAddressesPayload {
  target_address: ThemeEngineMergeAddressesAddressData;
  source_addresses: ThemeEngineMergeAddressesAddressData[];
  delete_source_address?: boolean;
  next_charge_date?: IsoDateString;
}

export async function mergeAddresses(context: ApiContext, payload: Payloads.MergeAddressesPayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses/merge`);

  // Generate the payload of the endpoint
  const themeEnginePayload: ThemeEngineMergeAddressesPayload = {
    target_address: { id: payload.targetAddress },
    source_addresses: payload.sourceAddresses.map(addressId => ({ id: addressId })),
    delete_source_address: payload.deleteSourceAddress,
    next_charge_date: payload.nextChargeDate,
  };

  const response = await post<AddressThemeEngineResponse, ThemeEngineMergeAddressesPayload>(
    endpointUrl,
    themeEnginePayload
  );
  return response.address;
}
