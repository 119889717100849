import { Plan, Product } from '@customer-portal/affinity-api';
import { uniq } from './array';
import { calculateDiscount } from './money';
import { PortalPermissions } from './portalPermissionControls';
import { getActivePlans, isInactiveProduct, isOnetimeOnlyProduct } from './plans';

const DEFAULT_VARIANT = 'Default Variant';

export const DEFAULT_VARIANT_TITLE = 'Default Title';

export const getProducts = (products: Product[] = []) =>
  products.filter(product => !product.tags.includes('rc:ignore'));

export const productPrices = (product: Product) => {
  const variants = uniq(
    product.variants?.map(variant => ({
      price: Number(variant.price),
      compareAtPrice: Number(variant.compare_at_price),
    }))
  );

  // The displayed price and compareAtPrice are infer from the first variant
  const selectedVariant = variants[0];

  const price = selectedVariant.price;

  const compareAtPrice = selectedVariant.compareAtPrice;

  const hasCompareAtPrice = compareAtPrice > price;

  const pricesAsc = variants.sort((price1, price2) => (price1.price > price2.price ? 1 : -1));

  const lowestPriceVariant = pricesAsc[0];

  const highestPriceVariant = pricesAsc[variants.length - 1];

  return {
    price,
    compareAtPrice,
    hasCompareAtPrice,
    pricesAsc,
    lowestPriceVariant,
    highestPriceVariant,
  };
};

/**
 * Calculates the variant price properties in a given frequencyMode
 *
 * 1. When frequencyMode is `onetime`
 * - price: Infer from `variant.price`
 * - compareAtPrice: Infer from `variant.compare_at_price`
 *
 * 2. When frequency is `subscription`
 *  - price: Infer `variant.price` applying product discount
 *  -
 * 3. `hasCompareAtPrice` is only true when `compareAtPrice` is higher than the price
 */
export type SellingFrequency = 'subscription' | 'onetime' | 'inactive';

interface InferVariantPriceOptions {
  plan: Plan | null | undefined;
  plans: Plan[];
  variant: Product['variants'][number] | undefined;
  frequencyType: SellingFrequency | null;
  subscribeAndSaveDiscountIsEnabled: boolean;
}
export const inferVariantPrice = ({
  variant,
  plan,
  plans,
  frequencyType,
  subscribeAndSaveDiscountIsEnabled,
}: InferVariantPriceOptions) => {
  const discountAmount = plan?.discountAmount ? Number(plan.discountAmount) : 0;
  const discountType = plan?.discountType || 'percentage';

  const _frequencyType = frequencyType || (isOnetimeOnlyProduct(plans) ? 'onetime' : 'subscription');
  const showPriceWithoutDiscount = _frequencyType === 'onetime' && !subscribeAndSaveDiscountIsEnabled;

  const variantPrice = Number(variant?.price ?? 0);
  const variantCompareAtPrice = Number(variant?.compare_at_price ?? 0);

  const price = showPriceWithoutDiscount ? variantPrice : calculateDiscount(variantPrice, discountAmount, discountType);

  const compareAtPrice = showPriceWithoutDiscount
    ? variantCompareAtPrice
    : Math.max(variantPrice, variantCompareAtPrice);

  return {
    price,
    compareAtPrice,
    hasCompareAtPrice: compareAtPrice > price,
  };
};

export const getSellingFrequency = (product: Product): SellingFrequency => {
  if (isInactiveProduct(product.plans)) return 'inactive';
  if (isOnetimeOnlyProduct(product.plans)) return 'onetime';
  return 'subscription';
};

/**
 * This formats the product title according to the rules we expect in the Customer Portal.
 *
 * - Removes (Off Auto renew) text from title.
 *
 * @param title The title of the product as returned by the server
 * @returns The title of the product after applying the format rules
 */
export const formatProductTitle = (title: string) => title.replace(/((\d+(.\d+)?% Off)? Auto renew)/, '').trimEnd();

export const productHasOnlyDefaultVariant = (product: Product) =>
  product.variants.length === 1 && product.variants[0].title === 'Default Title';

export const productHasDiscount = (product: Product) =>
  product.plans.some(plan => plan.discountAmount != null && Number(plan.discountAmount) > 0);

/**
 * Variants
 */
export const resolveVariant = (variants: Product['variants'] = [], id: Product['variants'][number]['shopify_id']) => {
  return variants.find(v => v.shopify_id === id);
};

export const resolveVariantByName = (variants: Product['variants'], variantOptions: string[]) => {
  const variantTitle = variantOptions.filter(value => Boolean(value)).join(' / ');

  return variants.find(v => v.title === variantTitle);
};

/**
 * Given a variant, returns the variant options as an array of strings
 */
export const resolveVariantOptions = (variant?: Product['variants'][number]) => {
  return [variant?.option1, variant?.option2, variant?.option3].filter(option => !!option) as string[];
};

export const isDefaultVariantTitle = (title: string) => title === DEFAULT_VARIANT || title === DEFAULT_VARIANT_TITLE;

export const formatVariantTitle = (title: string) =>
  isDefaultVariantTitle(title) ? title.replace(DEFAULT_VARIANT, '').replace(DEFAULT_VARIANT_TITLE, '') : title;

/**
 * Checks if variant is available, conditions are:
 *
 * `inventory_behaviour` portal setting controls Recharge behavior when creating orders.
 *
 * When `inventory_behaviour` is set to `decrement_obeying_policy`, variant is considered available when one of
 * the following conditions is `true`:
 *  1. If you have no inventory management: Inventory is not being tracked, should be considered available.
 *  2. If the variant has inventory greater than `0`.
 *  3. If the inventory policy is set to `continue` selling out-of-stock.
 *
 * When `inventory_behaviour` is `bypass` or `decrement_ignoring_policy`
 *  1. Inventory policy should be ignored.
 */
export const isVariantAvailable = ({
  inventory_behaviour,
  inventory_quantity = 0,
  inventory_policy = 'deny',
  inventory_management = undefined,
}: {
  inventory_behaviour?: PortalPermissions['inventory_behaviour'];
  inventory_quantity?: Product['variants'][number]['inventory_quantity'];
  inventory_policy?: Product['variants'][number]['inventory_policy'];
  inventory_management?: Product['variants'][number]['inventory_management'];
}) =>
  inventory_behaviour === 'decrement_obeying_policy'
    ? !inventory_management || inventory_quantity > 0 || inventory_policy === 'continue'
    : true;

export const isPrepaid = (product: Product) => {
  const activePlans = getActivePlans(product.plans);
  return activePlans.length > 0 && activePlans.every(plan => plan.type === 'prepaid');
};

export const getProductImagePath = (product: Product, selectedVariantTitle?: string): string => {
  const productImage = product?.images.length && product?.images[0].large;

  const variant = product.variants.find(({ title }) => title === selectedVariantTitle) || product.variants[0];
  const variantImage = variant?.image?.large;

  return variantImage || productImage || '';
};
