import { Children, ReactNode } from 'react';
import { Box } from '../Box';
import { Column, Columns } from '../Columns/Columns';
import { Stack } from '../Stack/Stack';
import * as styles from './Layout.css';

export interface Layout {
  children: ReactNode;
  variant?: '2/3' | 'full';
  reverse?: boolean;
}

export const Layout = ({ children, variant = '2/3', reverse = false }: Layout) => {
  if (variant === 'full') {
    return <Stack space="xxlarge">{children}</Stack>;
  }

  return (
    <Box className={styles.root}>
      <Stack space="xxlarge">
        <Columns
          space="xxxlarge"
          direction={{
            desktop: reverse ? 'row-reverse' : 'row',
            mobile: 'column',
          }}
        >
          {Children.toArray(children).map((child, index) => {
            if (index === 0) {
              return (
                <Column key={index} width="2/3">
                  {child}
                </Column>
              );
            }

            return (
              <Column key={index} width="1/3">
                {child}
              </Column>
            );
          })}
        </Columns>
      </Stack>
    </Box>
  );
};
