import { z } from 'zod';

import { ChargeModel } from '~/models/chargeModel';
import { ProductModel } from '~/models/productModel';
import { SubscriptionModel } from '~/models/subscriptionModel';
import { IsoDatetimeStringModel } from './commonModels';
import { OrderModel } from './orderModel';

export const DeliveryScheduleSubscription = SubscriptionModel.merge(
  z.object({
    charge_interval_unit_type: z.union([z.literal('day'), z.literal('week'), z.literal('month')]),
    product: ProductModel.optional(),
  })
);

export const DeliveryScheduleOrder = z.object({
  charge: ChargeModel,
  is_prepaid: z.boolean(),
  is_skippable: z.boolean(),
  is_skipped: z.boolean(),
  price: z.string(),
  order: OrderModel.or(z.object({})),
  shipment_type: z.union([z.literal('PROJECTED_SHIPMENT'), z.string()]),
  subscription: DeliveryScheduleSubscription,
});

export const DeliveryScheduleModel = z.object({
  date: IsoDatetimeStringModel,
  orders: z.array(DeliveryScheduleOrder),
});
