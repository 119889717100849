import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineCharge, ChargeThemeEngineResponse } from '~/types/api/response/charges';

import { post } from '~/common/rest';

export async function processCharge(
  context: ApiContext,
  { id }: Payloads.ProcessChargePayload
): Promise<ThemeEngineCharge> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/charges/${id}/process`);
  const response = await post<ChargeThemeEngineResponse>(endpointUrl);
  return response.charge;
}
