import clsx from 'clsx';
import { AllHTMLAttributes, AriaAttributes, ElementType, forwardRef, ReactNode } from 'react';
import { base } from './Box.css';
import { Sprinkles, sprinkles } from './sprinkles.css';

export type Box = Omit<AllHTMLAttributes<HTMLElement>, 'width' | 'height' | 'color' | 'checked'> & {
  checked?: boolean | 'mixed' | undefined;
} & AriaAttributes &
  Sprinkles & {
    as?: ElementType;
    children?: ReactNode;
  };

export const Box = forwardRef<HTMLElement, Box>(({ as = 'div', children, className, ...props }, ref) => {
  const Tag: ElementType = as;

  const sprinkleProps: Record<string, string> = {};

  const nativeProps: Record<string, unknown> = {};

  for (const key in props) {
    if (sprinkles.properties.has(key as keyof Sprinkles)) {
      sprinkleProps[key] = props[key as keyof typeof props];
    } else {
      nativeProps[key] = props[key as keyof typeof props];
    }
  }

  const styles = clsx(base, sprinkles(sprinkleProps), className);

  return (
    <Tag {...nativeProps} className={styles} ref={ref}>
      {children}
    </Tag>
  );
});

Box.displayName = 'Box';
