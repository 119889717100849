import { z } from 'zod';
import { BooleanLikeModel, IsoDatetimeStringModel } from './commonModels';

const EngineDiscountChannelSettingsModel = z.object({
  api: z.object({ can_apply: z.boolean() }),
  checkout_page: z.object({ can_apply: z.boolean() }),
  customer_portal: z.object({ can_apply: z.boolean() }),
  merchant_portal: z.object({ can_apply: z.boolean() }),
});

const EngineDiscountModel = z.object({
  id: z.number(),
  code: z.string(),
  value: z.number(),
  ends_at: z.string().nullable(),
  starts_at: z.string().nullable(),
  status: z.union([z.literal('disabled'), z.literal('enabled'), z.literal('fully_disabled')]),
  usage_limit: z.number().nullable(),
  applies_to_id: z.number().nullable(),
  discount_type: z.union([z.literal('fixed_amount'), z.literal('percentage'), z.literal('shipping')]),
  applies_to: z.string().nullable(),
  applies_to_resource: z.union([z.literal('shopify_product'), z.literal('shopify_collection_id')]).nullable(),
  times_used: z.number().nullable(),
  duration: z.union([z.literal('forever'), z.string()]),
  duration_usage_limit: z.number().nullable(),
  applies_to_product_type: z.union([z.literal('ALL'), z.literal('SUBSCRIPTION'), z.literal('ONETIME')]).nullable(),
  once_per_customer: z.boolean(),
  prerequisite_subtotal_min: z.string().nullable(),
  channel_settings: EngineDiscountChannelSettingsModel,
  first_time_customer_restriction: z.literal('customer_must_not_exist_in_recharge').nullable(),
  external_discount_source: z
    .union([z.literal('big_commerce'), z.literal('shopify'), z.literal('recharge'), z.literal('headless')])
    .nullable(),
  external_discount_id: z.string().nullable(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,
});

export const RetentionStrategyModel = z.object({
  id: z.number(),
  prevention_text: z.string(),
  discount_code: z.string().nullable(),
  incentive_type: z.union([
    z.string(),
    z.literal('discount'),
    z.literal('skip_charge'),
    z.literal('delay_subscription'),
    z.literal('swap_product'),
  ]),
  cancellation_reason: z.string(),
  cancellation_flow_type: z.union([z.literal('membership'), z.literal('subscription')]),
  discount: EngineDiscountModel.nullable(),
  reason: z.string(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,
});

const EngineShippingProvinceModel = z.object({
  code: z.string(),
  id: z.number(),
  name: z.string(),
});

const EngineShippingCountryModel = z.object({
  code: z.string(),
  country_id: z.number(),
  id: z.number(),
  name: z.string(),
  provinces: z.array(EngineShippingProvinceModel),
});

const PresentmentCurrenciesSymbolModel = z.object({
  currency: z.string(),
  location: z.string(),
  suffix: z.string(),
  symbol: z.string(),
});

const EngineStoreSettingsModel = z.object({
  bundles_enabled: z.boolean(),
  checkout_logo_url: z.string(),
  checkout_platform: z.union([
    z.literal('recharge'),
    z.literal('shopify'),
    z.literal('paypal'),
    z.literal('authorize'),
  ]),
  created_at: z.string(),
  currency: z.string(),
  customer_portal_domain: z.string(),
  domain: z.string(),
  email: z.string(),
  enabled_presentment_currencies: z.array(z.string()),
  enabled_presentment_currencies_symbols: z.array(PresentmentCurrenciesSymbolModel),

  external_platform: z.union([z.literal('shopify'), z.string()]),
  has_preview_customer: z.boolean(),
  iana_timezone: z.string(),
  disabled_currencies_historical: z.array(z.unknown()),
  id: z.number(),
  migrated_to_shopify_checkout_integration: BooleanLikeModel,
  my_shopify_domain: z.string(),
  name: z.string(),
  payment_processor: z.string(),
  platform_domain: z.string(),
  shop_email: z.string(),
  shop_phone: z.string(),
  subscriptions_enabled: BooleanLikeModel,
  timezone: z.string(),
  test_mode: z.boolean(),
  updated_at: z.string(),
  use_single_payment_method: BooleanLikeModel,
});

const CustomerPortalCustomCodeModel = z.object({
  backend_portal: z.string(),
  credit_cart_update_page: z.string(),
  footer: z.string(),
  header: z.string(),
  header_logo_url: z.string().nullable(),
});

const CustomerPortalOnetimeModel = z.object({
  enabled: z.boolean(),
  available_products: z.string().nullable(),
  shopify_collection_id: z.number().nullable(),
  zero_inventory_purchase: z.boolean(),
  subscribe_and_save_discount_enabled: z.boolean(),
});

const CustomerPortalSubscriptionModel = z.object({
  add_product: z.boolean(),
  cancel_subscription: z.boolean(),
  cancellation_email_contact: z.string().nullable(),
  cancellation_enable_pause_options: z.boolean(),
  cancellation_enable_pause_options_values: z.string(),
  cancellation_minimum_order_count: z.number(),
  cancellation_reason_optional: z.boolean(),
  change_product: z.boolean(),
  change_quantity: z.boolean(),
  change_variant: z.boolean(),
  edit_order_frequency: z.union([z.literal('Limited'), z.literal('Any'), z.literal('Prohibited')]),
  change_frequency_recalculate_next_charge: z.boolean(),
  edit_scheduled_date: z.boolean(),
  reactivate_subscription: z.boolean(),
  skip_scheduled_order: z.boolean(),
  skip_prepaid_order: z.boolean(),
  zero_inventory_purchase: z.boolean(),
});

const CustomerPortalMembershipModel = z.object({
  allow_membership_cancellation_after: z.boolean(),
  allow_membership_cancellation_in_customer_portal: z.boolean(),
  membership_cancellation_reason_optional: z.boolean(),
});

const CustomerPortalSettingsModel = z.object({
  affinity_plans: z.boolean(),
  can_use_plans: z.boolean(),
  can_use_affinity_extensions: z.boolean(),
  collection_ids: z.array(z.number()),
  custom_code: CustomerPortalCustomCodeModel,
  discount_input: z.boolean(),
  edit_shipping_address: z.boolean(),
  enable_membership_programs: z.boolean(),
  force_customer_portal_accounts: z.boolean(),
  hosted_customer_portal: z.boolean(),
  gift_skipped_shipment_enabled: z.boolean(),
  inventory_behaviour: z.union([
    z.literal('decrement_obeying_policy'),
    z.literal('bypass'),
    z.literal('decrement_ignoring_policy'),
  ]),
  prevent_payment_method_creation: z.boolean(),
  prevent_address_creation: z.boolean(),
  subscription: CustomerPortalSubscriptionModel,
  membership: CustomerPortalMembershipModel,
  view_order_schedule: z.boolean(),
  view_recharge_payment_methods: z.boolean(),
  wfs_flows_enabled: z.union([z.literal(0), z.literal(1)]),
  show_credits: z.boolean(),
  onetime: CustomerPortalOnetimeModel,
  wfs_active_churn: z.boolean(),
  wfs_cp_show_sd: z.boolean(),
  affinity_sidebar_position: z.union([z.literal('left'), z.literal('right')]),
  gifting_is_enabled: z.boolean(),
});

const CustomerPortalStoreSettings = z.object({
  customer_portal: CustomerPortalSettingsModel,
  has_shopify_connector: z.boolean(),
  multicurrency_enabled: z.boolean(),
  shop_id: z.boolean(),
});

export const StoreModel = z.object({
  retention_strategies: z.array(RetentionStrategyModel),
  shipping_countries: z.array(EngineShippingCountryModel),
  store: EngineStoreSettingsModel,
  settings: CustomerPortalStoreSettings,
});
