import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import { getPaymentMethods } from './paymentMethods';

import { remove } from '~/common/rest';

export async function getPaymentMethod(context: ApiContext, { id }: Payloads.GetPaymentMethodPayload) {
  const paymentMethods = await getPaymentMethods(context, { id });

  if (paymentMethods.length === 0) return null;
  return paymentMethods[0];
}

export async function deletePaymentMethod(
  context: ApiContext,
  { id }: Payloads.DeletePaymentMethodPayload
): Promise<void> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/payment_methods/${id}`);
  await remove(endpointUrl);
}
