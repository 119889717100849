import type { ThemeEngineOnetime } from '~/types/api/response/onetimes';
import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

import { get } from '~/common/rest';
import {
  ensureArray,
  generateNextPageFilters,
  generatePreviousPageFilters,
  hasNextPageMetaless,
  hasPreviousPageMetaless,
  mapPlan,
  mapSubscriptionProductToPlans,
  normalizeProduct,
} from '../utils';
import { ShopifyThemeEngineProduct } from '~/types/api/response/products';
import { SettingsThemeEngineResponse } from '~/types/api/response/store';

export async function getOnetimes(context: ApiContext, payload: Payloads.GetOnetimesPayload = {}) {
  interface OnetimesWithProductThemeEngineResponse extends SettingsThemeEngineResponse {
    onetimes: (ThemeEngineOnetime & { product?: ShopifyThemeEngineProduct })[];
  }

  const schema = { settings: {}, onetimes: payload };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<OnetimesWithProductThemeEngineResponse>(endpointUrl);

  const planMappingFn = response.settings.customer_portal.affinity_plans ? mapPlan : mapSubscriptionProductToPlans;
  const onetimes = ensureArray(response.onetimes).map(onetime => ({
    ...onetime,
    product: onetime.product ? normalizeProduct(onetime.product, planMappingFn) : undefined,
  }));

  return {
    next: hasNextPageMetaless(payload, onetimes) ? generateNextPageFilters(payload) : null,
    previous: hasPreviousPageMetaless(payload) ? generatePreviousPageFilters(payload) : null,
    onetimes: onetimes,
  };
}

export async function getOnetime(context: ApiContext, { id }: Payloads.GetOnetimePayload) {
  const { onetimes } = await getOnetimes(context, { id });
  return onetimes.length > 0 ? onetimes[0] : null;
}
