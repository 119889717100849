import { ReactNode } from 'react';
import { Box } from '../Box';
import * as style from './Icon.css';

export type Icon = style.IconVariants & {
  children: ReactNode;
  inner?: Box['padding'];
};

export const Icon = ({ size = 'xxxlarge', inner = 'xsmall', children }: Icon) => {
  return (
    <Box
      lineHeight="none"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      className={`recharge-icon ${style.icon({ size })}`}
      padding={inner}
    >
      {children}
    </Box>
  );
};
