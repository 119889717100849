import { ReactNode, useMemo } from 'react';
import { ThemeEngineStrategy } from '@customer-portal/affinity-api';
import { RechargeContext } from '../context/RechargeContext';
import { Authentication } from '../hooks/useAuthentication';
import { useToken } from '../hooks/useToken';
import { useListenToRefreshEvent } from '../extensions/useListenToRefreshEvent';

export function RechargeProvider({ children }: { children: ReactNode }) {
  const token = useToken();
  useListenToRefreshEvent();

  const auth: Authentication = useMemo(() => token, []);

  const apiStrategy = useMemo(() => ThemeEngineStrategy(auth.hash || ''), []);

  const value = useMemo(() => ({ auth, apiStrategy }), []);

  return <RechargeContext.Provider value={value}>{children}</RechargeContext.Provider>;
}
