import type { ApiContext } from '~/types/strategies';
import { get } from '~/common/rest';
import type { CustomerCreditSummary } from '~/types/responses';

type ThemeEngineResponse = { credit_summary: CustomerCreditSummary };

export async function getCustomerCreditSummary(context: ApiContext) {
  const schema = { credit_summary: {} };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ThemeEngineResponse>(endpointUrl);
  return response.credit_summary;
}
