import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type * as Requests from '~/types/api/request/flows';
import type * as Responses from '~/types/api/response/flows';

import { post } from '~/common/rest';

export async function getCancelationFlowReasons(
  context: ApiContext,
  { id }: Payloads.CancelationFlowReasonsPayload
): Promise<Responses.ThemeEngineCancelationFlowReasonsResponse['reasons']> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/flows/cancel_request_info');
  const response = await post<
    Responses.ThemeEngineCancelationFlowReasonsResponse,
    Requests.ThemeEngineCancelationFlowReasonsPayload
  >(endpointUrl, {
    subscription_id: id,
  });
  return response.reasons;
}

export async function getCancelationOffersByReason(
  context: ApiContext,
  { id }: Payloads.CancelationFlowOffersPayload
): Promise<Responses.ThemeEngineCancelationFlowOffersResponse['reason_offer_lookup']> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/flows/get_offer_data_for_reason');
  const response = await post<
    Responses.ThemeEngineCancelationFlowOffersResponse,
    Requests.ThemeEngineCancelationFlowOffersPayload
  >(endpointUrl, {
    reason_id: id,
  });
  return response.reason_offer_lookup;
}

export async function flowClaimOffer(
  context: ApiContext,
  { id }: Payloads.CancelationFlowClaimOfferPayload
): Promise<Responses.ThemeEngineCancelationFlowClaimOfferResponse> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/flows/claim_offer');
  const response = await post<
    Responses.ThemeEngineCancelationFlowClaimOfferResponse,
    Requests.ThemeEngineCancelationFlowClaimOfferPayload
  >(endpointUrl, {
    claim_id: id,
  });

  return response;
}
