import { useSearchParams } from 'react-router-dom';
import { useGetStoreData } from '../../../theme/src/api/useGetStoreData';
import { useAuthentication } from '../../../theme/src/hooks/useAuthentication';

export const useRedirect = () => {
  const { data, isLoading } = useGetStoreData();
  const [params] = useSearchParams();
  const { token, hash } = useAuthentication();

  const redirect = () => {
    const redirectUrl = params.get('redirect');

    if (redirectUrl) {
      window.location.href = decodeURIComponent(redirectUrl);
      return;
    }

    if (data && token && hash) {
      window.location.href = `https://${data?.store.domain}/tools/recurring/portal/${hash}/overview?token=${token}`;
      return;
    }
  };

  return {
    isLoading,
    redirect,
  };
};
