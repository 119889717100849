import { z } from 'zod';
import { IsoDatetimeStringModel } from './commonModels';

import { SubscriptionModel } from './subscriptionModel';

const PaymentTypeModel = z.union([
  z.string(),
  z.union([
    z.literal('credit_card'),
    z.literal('paypal'),
    z.literal('apple_pay'),
    z.literal('google_pay'),
    z.literal('sepa_debit'),
    z.literal('ideal'),
    z.literal('shop_pay'),
    z.literal('paypal_express'),
    z.literal('sofort'),
    z.literal('giropay'),
  ]),
]);

const ProcessorNameModel = z.union([
  z.string(),
  z.union([
    z.literal('stripe'),
    z.literal('adyen'),
    z.literal('authorize'),
    z.literal('braintree'),
    z.literal('shopify_payments'),
    z.literal('shopify_app_subscription_billing'),
    z.literal('mollie'),
  ]),
]);

export const CreditCardDetailModel = z.object({
  brand: z.union([z.string(), z.literal('visa'), z.literal('mastercard')]).nullable(),
  exp_month: z.number().nullable(),
  exp_year: z.number().nullable(),
  last4: z.union([z.number(), z.string()]).nullable(),
});

export const ShopPayDetailModel = z.object({
  cardholder_name: z.string(),
  inactive: z.boolean(),
  exp_month: z.number(),
  exp_year: z.number(),
  last4: z.union([z.number(), z.string()]),
});

export const PaypalDetailModel = z.object({
  paypal_email: z.string().email(),
  paypal_payer_id: z.number(),
});

export const PaymentDetailSEPA = z.object({
  bank_code: z.string(),
  country: z.string(),
  external_mandate_id: z.string(),
  last4: z.string(),
});

const PaymentMethodDetailModel = z.union([
  CreditCardDetailModel,
  ShopPayDetailModel,
  PaypalDetailModel,
  PaymentDetailSEPA,
]);

export const PaymentMethodModel = z.object({
  id: z.number(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,
  customer_id: z.number(),
  default: z.boolean(),
  payment_details: PaymentMethodDetailModel,
  payment_type: PaymentTypeModel.nullable(),
  processor_customer_token: z.string(),
  processor_name: ProcessorNameModel,
  processor_payment_token: z.string(),
  subscriptions: z.array(SubscriptionModel),
});
