import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineSubscription, SubscriptionThemeEngineResponse } from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';

export async function activateSubscription(
  context: ApiContext,
  { id }: Payloads.ActivateSubscriptionPayload
): Promise<ThemeEngineSubscription> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/subscriptions/${id}/activate`);
  const response = await post<SubscriptionThemeEngineResponse>(endpointUrl);
  return response.subscription;
}
