export function useCheckFlexGap() {
  // Create flex container with row-gap set
  //Use a span elemnt instead of a div element to avoid conflicts with merchants styles that may be applied to div elements
  const flex = document.createElement('span');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // Create two, elements inside it
  flex.appendChild(document.createElement('span'));
  flex.appendChild(document.createElement('span'));

  // Append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1; // Flex container should be 1px high from the row-gap
  flex?.remove();

  return isSupported;
}
