import AccountIcon from './account.svg';
import Alert from './alert.svg';
import AlertOutline from './alert-outline.svg';
import CheckIcon from './check.svg';
import ChevronDownIcon from './chevron-down.svg';
import ChevronLeftIcon from './chevron-left.svg';
import ChevronRightIcon from './chevron-right.svg';
import CircleCheckIcon from './circle-check.svg';
import CloseIcon from './close.svg';
import DelayIcon from './delay.svg';
import DeleteIcon from './delete.svg';
import EditIcon from './edit.svg';
import GiftIcon from './gift.svg';
import InfoIcon from './info.svg';
import LogoutIcon from './logout.svg';
import MinusIcon from './minus.svg';
import PlusIcon from './plus.svg';
import RemoveIcon from './remove.svg';
import SearchIcon from './search.svg';
import SkipIcon from './skip.svg';
import SpinnerIcon from './spinner.svg';
import SwapIcon from './swap.svg';
import WarningIcon from './warning.svg';

export const Iconography = {
  AccountIcon,
  Alert,
  AlertOutline,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleCheckIcon,
  CloseIcon,
  DelayIcon,
  DeleteIcon,
  EditIcon,
  GiftIcon,
  InfoIcon,
  LogoutIcon,
  MinusIcon,
  PlusIcon,
  RemoveIcon,
  SearchIcon,
  SkipIcon,
  SpinnerIcon,
  SwapIcon,
  WarningIcon,
};
