import { ReactNode, useCallback, useState } from 'react';

import { useModalProps_DEPRECATED, useModal_DEPRECATED } from '@customer-portal/affinity-components';
import { createContext } from 'react';

type Context = {
  view: useModalProps_DEPRECATED;
  context: null | ReactNode;
  setContext: (context?: ReactNode) => void;
};

const defaultValue: Context = {
  context: null,
  setContext: () => undefined,
  view: {
    open: async () => undefined,
    close: async () => undefined,
    isOpen: false,
    isRendered: false,
    // @TODO: these are placeholders. Update later. Ask @david or @alejandro
    isRenderedLookup: () => false,
    isOpenLookup: () => false,
  },
};

export const ViewsContext = createContext<Context>(defaultValue);

export function ViewsProvider({ children }: { children: JSX.Element | ReactNode }) {
  const view = useModal_DEPRECATED();

  const [context, _setContext] = useState<ReactNode | null>(null);

  const setContext = useCallback((context?: ReactNode) => {
    _setContext(context || null);
  }, []);

  return <ViewsContext.Provider value={{ view, context, setContext }}>{children}</ViewsContext.Provider>;
}
