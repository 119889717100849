import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { UpdateSubscriptionPayload } from '~/types/api/request/subscriptions';
import type {
  ThemeEngineSubscription,
  SubscriptionThemeEngineResponse,
  SubscriptionsThemeEngineResponse,
} from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';

export async function updateSubscription(
  context: ApiContext,
  { id, payload }: Payloads.UpdateSubscriptionPayload
): Promise<ThemeEngineSubscription> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/subscriptions/${id}`);
  const response = await post<SubscriptionThemeEngineResponse, UpdateSubscriptionPayload>(endpointUrl, payload);
  return response.subscription;
}

export async function updateSubscriptions(
  context: ApiContext,
  { addressId, payload }: Payloads.UpdateSubscriptionsPayload
) {
  const endpointUrl = context.urlGenerator(
    context.baseUrl,
    context.token,
    `/addresses/${addressId}/subscriptions-bulk-update`
  );

  const response = await post<SubscriptionsThemeEngineResponse>(endpointUrl, { subscriptions: payload });
  return response.subscriptions;
}
