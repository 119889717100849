import { ExperienceDiscountAddItem } from '@customer-portal/affinity-api';
import { Heading, Stack, Text } from '@customer-portal/affinity-components';

export const UpsellHeading = ({ metadata }: { metadata: ExperienceDiscountAddItem['metadata'] }) => {
  const title = metadata.title?.value;
  const body = metadata.body_text?.value;
  const bodyHtml = metadata.body_html?.value;

  return (
    <Stack space="none">
      {title && <Heading variant="h1">{title}</Heading>}

      {body && <Text variant="p3">{body}</Text>}

      {bodyHtml && (
        <Text variant="p3">
          <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        </Text>
      )}
    </Stack>
  );
};
