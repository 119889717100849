import {
  ErrorBoundary,
  BrowserClient,
  defaultStackParser,
  defaultIntegrations,
  makeFetchTransport,
  Hub,
} from '@sentry/react';

const client = new BrowserClient({
  dsn: 'https://32e5a93ab278411c92de018b4993d316@o58632.ingest.sentry.io/4504164418387968',
  integrations: defaultIntegrations,
  environment: import.meta.env.PROD ? 'PROD' : 'DEV',
  enabled: import.meta.env.PROD,
  autoSessionTracking: false,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
});

const hub = new Hub(client);

const initSentrySession = () => {
  hub.startSession({ ignoreDuration: true });
  hub.captureSession();
};

const setupSentry = () => {
  hub.configureScope(function (scope) {
    const shop = window.Shopify?.shop || '';
    scope.setTag('shop', shop);
  });

  // Only create a session if don't have one yet
  const currentSession = hub.getScope()?.getSession();
  if (!currentSession) {
    initSentrySession();
  }
};

export { ErrorBoundary, hub as Sentry, setupSentry };
