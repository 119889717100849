import { z } from 'zod';
import { IsoDatetimeStringModel, PropertyModel } from '~/models/commonModels';
import { BundleSelectionsModel } from './bundleModel';

export const OnetimeModel = z.object({
  id: z.number(),
  address_id: z.number(),
  created_at: IsoDatetimeStringModel,
  customer_id: z.number(),
  next_charge_scheduled_at: z.string(),
  presentment_currency: z.string(),
  price: z.number(),
  product_title: z.string(),
  properties: z.array(PropertyModel),
  quantity: z.number(),
  recharge_product_id: z.number(),
  shopify_product_id: z.number(),
  shopify_variant_id: z.number(),
  sku: z.string().nullable(),
  status: z.literal('ONETIME'),
  updated_at: IsoDatetimeStringModel,
  variant_title: z.string(),
  bundle_product: z.any().nullable().optional(),
  bundle_selections: BundleSelectionsModel.nullable().optional(),
});
