import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineApplyDiscountPayload } from '~/types/api/request/addresses';
import type { AddressThemeEngineResponse, DiscountThemeEngineResponse } from '~/types/api/response/addresses';

import { get, post } from '~/common/rest';
import { Discount } from '~/types/responses';

export async function getDiscount(context: ApiContext, { id }: Payloads.GetDiscountPayload): Promise<Discount> {
  const schema = { discount: { id } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/request_objects`, params);

  const response = await get<DiscountThemeEngineResponse>(endpointUrl);
  return response.discount;
}

export async function applyDiscount(context: ApiContext, payload: Payloads.ApplyDiscountCodePayload) {
  const { addressId, discountCode } = payload;
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses/${addressId}/apply_discount`);
  const themeEnginePayload = { discount_code: discountCode };

  const response = await post<AddressThemeEngineResponse, ThemeEngineApplyDiscountPayload>(
    endpointUrl,
    themeEnginePayload
  );
  return response.address;
}

export async function removeDiscount(context: ApiContext, payload: Payloads.RemoveDiscountCodePayload) {
  const { addressId } = payload;
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses/${addressId}/remove_discount`);

  const response = await post<AddressThemeEngineResponse>(endpointUrl, {});
  return response.address;
}

export async function editDiscount(context: ApiContext, payload: Payloads.ApplyDiscountCodePayload) {
  const { addressId } = payload;

  // Remove the discount from the address
  await removeDiscount(context, { addressId });

  // Create it again with the new discount
  return applyDiscount(context, payload);
}
