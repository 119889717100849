import { ReactNode } from 'react';
import { Box } from '../Box';
import * as styles from './Card.css';

export type Card = styles.CardVariants & {
  children: ReactNode;
  rounded?: true | false | 'auto';
  paddingX?: Box['padding'];
  paddingY?: Box['padding'];
  component?: 'article' | 'aside' | 'details' | 'div' | 'main' | 'section';
};

export const Card = ({
  component = 'div',
  tone = 'regular',
  rounded = 'auto',
  paddingX = 'xxlarge',
  paddingY = 'xxlarge',
  children,
}: Card) => {
  return (
    <Box
      as={component}
      className={`recharge-card ${styles.root({ tone })}`}
      paddingX={paddingX}
      paddingY={paddingY}
      borderRadius={rounded === 'auto' ? { mobile: 'none', desktop: 'radius' } : rounded ? 'radius' : 'none'}
    >
      {children}
    </Box>
  );
};
