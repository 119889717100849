import { ApiClient } from '@customer-portal/affinity-api';

import { useAuthentication } from '../hooks/useAuthentication';
import { useApiStrategy } from '../hooks/useApiStrategy';

export const useApi = () => {
  const { token } = useAuthentication();

  const apiStrategy = useApiStrategy();

  const api = ApiClient(token || '', apiStrategy);

  return { api };
};
