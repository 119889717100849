import type { ApiContext } from '~/types/strategies';
import type { CreditAccounts } from '~/types/responses';
import { get } from '~/common/rest';

export async function getCreditAccounts(context: ApiContext) {
  const schema = { credit_accounts: {} };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const res = await get<CreditAccounts>(endpointUrl);
  return res;
}
