import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { CreateSubscriptionPayload } from '~/types/api/request/subscriptions';
import type { ThemeEngineSubscription, SubscriptionThemeEngineResponse } from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';

export async function createSubscription(
  context: ApiContext,
  payload: Payloads.CreateSubscriptionPayload
): Promise<ThemeEngineSubscription> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/subscriptions`);
  const response = await post<SubscriptionThemeEngineResponse, CreateSubscriptionPayload>(endpointUrl, payload);
  return response.subscription;
}
