import type { ApiStrategy } from '~/types/strategies';
import * as addressActions from './addresses';
import * as chargeActions from './charges';
import * as creditAccountActions from './creditAccounts';
import * as customersActions from './customers';
import * as customerActions from './customer';
import * as experiencesActions from './experiences';
import * as logoutAction from './logout';
import * as onetimeActions from './onetimes';
import * as orderActions from './orders';
import * as productActions from './products';
import * as storeActions from './store';
import * as subscriptionActions from './subscriptions';
import * as flows from './flows';
import * as membershipActions from './memberships';
import * as giftActions from './gifting';

function generateProxyUrl() {
  const proxyPort = import.meta.env['VITE_PROXY_PORT'] || 5174;
  const proxyHost = import.meta.env['VITE_PROXY_HOST'] ? `/custom-host/${import.meta.env['VITE_PROXY_HOST']}` : '';

  const proxyUrl = `http://localhost:${proxyPort}${proxyHost}`;
  return proxyUrl.endsWith('/') ? proxyUrl.slice(0, -1) : proxyUrl;
}

function ThemeEngineStrategy(customerHash: string): ApiStrategy {
  function getBaseUrl(): string {
    return `/portal/c0fb3cf4ef8aa43f4ba4c8331d0faa`;
  }

  return {
    ...addressActions,
    ...chargeActions,
    ...creditAccountActions,
    ...customersActions,
    ...customerActions,
    ...onetimeActions,
    ...orderActions,
    ...productActions,
    ...storeActions,
    ...subscriptionActions,
    ...experiencesActions,
    ...logoutAction,
    ...flows,
    ...membershipActions,
    ...giftActions,
    // Utilities
    getBaseUrl,
  };
}

export { ThemeEngineStrategy };
