import { z } from 'zod';
import { PaymentMethodModel } from '~/models/paymentMethodModel';
import { SubscriptionModel } from '~/models/subscriptionModel';
import { OnetimeModel } from '~/models/onetimeModel';
import { IsoDatetimeStringModel } from './commonModels';

export const AddressModel = z.object({
  id: z.number(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,
  address1: z.string(),
  address2: z.string(),
  cart_note: z.string().nullable(),
  city: z.string(),
  company: z.string(),
  country: z.string(),
  customer_id: z.number(),
  deleted_at: IsoDatetimeStringModel.nullable(),
  discount_id: z.number().nullable(),
  first_name: z.string(),
  last_name: z.string(),
  note_attributes: z.optional(z.array(z.object({ name: z.string(), value: z.string() }))),
  phone: z.string(),
  presentment_currency: z.union([z.string(), z.literal('USD')]),
  province: z.string(),
  shipping_lines_override: z.string().nullable(),
  zip: z.string(),
  subscriptions: z.optional(z.array(SubscriptionModel)),
  onetimes: z.optional(z.array(OnetimeModel)),
  include: z.object({ payment_methods: z.array(PaymentMethodModel) }),
});

const ChannelSettingsModel = z.object({
  api: z.object({ can_apply: z.boolean() }),
  checkout_page: z.object({ can_apply: z.boolean() }),
  customer_portal: z.object({ can_apply: z.boolean() }),
  merchant_portal: z.object({ can_apply: z.boolean() }),
});

export const DiscountModel = z.object({
  id: z.number(),
  applies_to: z.string().nullable(),
  applies_to_id: z.string().nullable(),
  applies_to_product_type: z.union([z.literal('ALL'), z.literal('SUBSCRIPTION'), z.literal('ONETIME')]),
  code: z.string(),
  channel_settings: ChannelSettingsModel,
  created_at: IsoDatetimeStringModel,
  discount_type: z.union([z.literal('fixed_amount'), z.literal('percentage'), z.literal('shipping')]),
  duration: z.string(),
  ends_at: IsoDatetimeStringModel.nullable(),
  external_discount_id: z.string().nullable(),
  external_discount_source: z.string().nullable(),
  first_time_customer_restriction: z.string().nullable(),
  once_per_customer: z.boolean(),
  prerequisite_subtotal_min: z.number().nullable(),
  starts_at: IsoDatetimeStringModel.nullable(),
  status: z.union([z.literal('enabled'), z.literal('disabled'), z.literal('fully_disabled')]),
  times_used: z.number(),
  updated_at: IsoDatetimeStringModel,
  usage_limit: z.number().nullable(),
  value: z.number(),
});
