import { z } from 'zod';
import { IsoDatetimeStringModel } from './commonModels';

export const GiftModel = z.object({
  credit_account_id: z.number(),
  gift_note: z.string(),
  sender_external_order_id: z.number(),
  sender_name: z.string(),
  redeemable_product_id: z.number(),
  redeemed_at: IsoDatetimeStringModel.nullable(),
});
