import { StoreData } from '@customer-portal/affinity-api';
import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

const STORE_DATA_QUERY_KEY = 'store-data';

type useQueryOptions<T = StoreData> = {
  enabled?: boolean;
  select?: (data: StoreData) => T;
  analytics?: { hasThemeCustomization: string };
};

export function useGetStoreData<T = StoreData>(
  { enabled, select, analytics }: useQueryOptions<T> = {
    enabled: true,
    select: undefined,
    analytics: undefined,
  }
) {
  const { api } = useApi();

  const hasInitialData =
    window.ReCharge?.store &&
    window.ReCharge?.settings &&
    window.ReCharge?.retention_strategies &&
    window.ReCharge?.shipping_countries;

  return useQuery<StoreData, unknown, T>([STORE_DATA_QUERY_KEY, analytics], () => api.getStoreData({ analytics }), {
    enabled,
    select,
    staleTime: Infinity,
    initialData: hasInitialData
      ? {
          store: window.ReCharge.store,
          settings: window.ReCharge.settings,
          retention_strategies: window.ReCharge.retention_strategies,
          shipping_countries: window.ReCharge.shipping_countries,
        }
      : undefined,
  });
}
