import type { ApiStrategy, ApiContext } from '~/types/strategies';

import { generateRequestUrl } from '~/strategies/themeEngine/utils';
import { AnalyticsDecorator, analyticsDecorator } from '~/decorators/analyticsDecorator';

/**
 * This type helps us omit the first parameter from a function that has two
 * parameters:
 * 1st parameter being an ApiContext
 * 2nd parameter being anything.
 *
 * It will return a function that only has a single parameter which will take
 * whatever type the second parameter has.
 */
type OmitFirstArg<F> = F extends (context: ApiContext, payload: infer P) => infer R
  ? F extends (context: ApiContext, payload?: P) => infer R
    ? (payload?: P) => R
    : (payload: P) => R
  : never;

/**
 * This is the signature type of the ApiClient, it will return an object
 * containing all the keys in the strategy and the functions without the
 * context parameter.
 */
type ApiClientProxy = { [K in keyof ApiStrategy]: AnalyticsDecorator<OmitFirstArg<ApiStrategy[K]>> };

type ApiClientOptions = { urlGenerator?: ApiContext['urlGenerator'] };

export function ApiClient(
  token: string,
  strategy: ApiStrategy,
  { urlGenerator = generateRequestUrl }: ApiClientOptions = {}
): ApiClientProxy {
  const context: ApiContext = { token, baseUrl: strategy.getBaseUrl(), urlGenerator };

  return new Proxy(strategy, {
    get: function (target, name: keyof ApiClientProxy) {
      return analyticsDecorator(context, target[name]);
    },
  }) as unknown as ApiClientProxy;
}
