import { z } from 'zod';
import { BundleProductModel } from './bundleModel';
import { PlanModel } from './planModel';

export const PresentmentPriceModel = z.object({
  currency: z.string(),
  discounted_price: z.string(),
  unit_price: z.string(),
});

export const ProductImageModel = z.object({
  large: z.string(),
  medium: z.string(),
  small: z.string(),
  original: z.string(),
  sort_order: z.number(),
});

// TODO: Divide the SubscriptionModel to provide these values
export const ProductSubscriptionModel = z.object({
  charge_interval_frequency: z.number(),
  expire_after_specific_number_of_charges: z.number().nullable(),
  order_day_of_month: z.number().nullable(),
  order_day_of_week: z.number().nullable(),
  order_interval_frequency: z.string(),
  order_interval_frequency_options: z.array(z.string()),
  order_interval_unit: z.union([z.literal('day'), z.literal('week'), z.literal('month')]),

  cutoff_day_of_month: z.number().nullable(),
  cutoff_day_of_week: z.number().nullable(),
  modifiable_properties: z.array(z.string()),
  number_charges_until_expiration: z.number().nullable(),
  storefront_purchase_options: z.union([
    z.literal('subscription_and_onetime'),
    z.literal('subscription_only'),
    z.literal('onetime_only'),
    z.literal('inactive'),
  ]),
});

export const SubscriptionProductModel = z.object({
  id: z.number(),
  title: z.string(),

  collection_ids: z.array(z.number()),
  discount_amount: z.number(),
  discount_type: z.union([z.literal('percentage'), z.literal('fixed_amount')]),
  handle: z.string(),
  images: z.array(ProductImageModel),
  subscription_defaults: ProductSubscriptionModel,
});

export const ShopifyProductOptionModel = z.object({
  name: z.string(),
  position: z.number(),
  shopify_id: z.number(),
  shopify_product_id: z.number(),
  values: z.array(z.string()),
});

export const ShopifyVariantModel = z.object({
  title: z.string(),

  inventory_management: z.union([z.literal('shopify'), z.string()]),
  inventory_policy: z.union([z.literal('deny'), z.literal('continue'), z.string()]),
  inventory_quantity: z.number(),

  option1: z.string().nullable(),
  option2: z.string().nullable(),
  option3: z.string().nullable(),
  compare_at_price: z.string().nullable(),
  image: ProductImageModel.nullable(),
  presentment_prices: z.array(PresentmentPriceModel).nullable(),
  price: z.string(),
  requires_shipping: z.boolean(),
  shopify_id: z.number(),
  shopify_product_id: z.number(),
  sku: z.string(),
  taxable: z.boolean(),
});

export const ShopifyProductDetailsModel = z.object({
  options: z.array(ShopifyProductOptionModel),
  tags: z.array(z.string()),
  variants: z.array(ShopifyVariantModel),
});

export const ShopifySubscriptionProductModel = SubscriptionProductModel.merge(
  z.object({
    shopify_details: ShopifyProductDetailsModel,
    shopify_product_id: z.number(),
    plans: z.array(PlanModel).optional(),
  })
);

export const ProductVariantModel = z.object({
  compare_at_price: z.string().nullable(),
  fulfillment_service: z.union([z.literal('manual'), z.string()]),
  image: ProductImageModel.nullable(),
  presentment_prices: z.array(PresentmentPriceModel).nullable(),

  inventory_management: z.union([z.literal('shopify'), z.string()]),
  inventory_policy: z.union([z.literal('deny'), z.literal('continue'), z.string()]),
  inventory_quantity: z.number(),

  option1: z.string().nullable(),
  option2: z.string().nullable(),
  option3: z.string().nullable(),
  price: z.string(),
  requires_shipping: z.boolean(),
  shopify_id: z.number(),
  shopify_product_id: z.number(),
  title: z.string(),
  sku: z.string(),
  taxable: z.boolean(),
});

export const ProductOptionModel = z.object({
  name: z.string(),
  position: z.number(),
  shopify_id: z.number(),
  shopify_product_id: z.number(),
  values: z.array(z.string()),
});

export const ProductModel = z.object({
  id: z.number(),

  collection_ids: z.array(z.number()),
  discount_amount: z.number(),
  discount_type: z.union([z.literal('percentage'), z.literal('fixed_amount')]),
  external_product_id: z.string(),
  handle: z.string(),
  images: z.array(ProductImageModel),
  subscription_options: ProductSubscriptionModel,
  tags: z.array(z.string()),
  title: z.string(),
  variants: z.array(ProductVariantModel),
  options: z.array(ProductOptionModel),

  bundle_product: BundleProductModel.nullable().optional(),
  plans: z.array(PlanModel),
});
