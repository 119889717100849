import { z } from 'zod';
import { ChargeAddressModel, ChargeDiscountModel, ShippingLineModel } from './chargeModel';
import { CustomerModel } from './customerModel';
import { InventoryPolicyModel } from './inventoryPolicyModel';
import { IsoDatetimeStringModel, PropertyModel } from './commonModels';
import { AddressModel } from './addressModel';

export const OrderLineItemModal = z.object({
  external_inventory_policy: InventoryPolicyModel,
  grams: z.number(),
  images: z.object({
    large: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
  }),
  price: z.number(),
  product_title: z.string(),
  properties: z.array(PropertyModel),
  quantity: z.number(),
  shopify_product_id: z.string(),
  shopify_variant_id: z.string(),
  sku: z.string(),
  subscription_id: z.number(),
  tax_lines: z.array(z.unknown()),
  title: z.string(),
  variant_title: z.string(),
});

export const OrderModel = z.object({
  address: AddressModel.optional(),
  address_id: z.number(),
  address_is_active: z.boolean().nullable(),
  billing_address: ChargeAddressModel,
  browser_ip: z.number().nullable(),
  charge_id: z.number(),
  charge_status: z.string(),
  created_at: IsoDatetimeStringModel,
  currency: z.string(),
  customer: CustomerModel,
  customer_id: z.number(),
  discount_codes: z.array(ChargeDiscountModel).nullable(),
  email: z.string(),
  error: z.string().nullable(),
  first_name: z.string(),
  hash: z.string(),
  id: z.number(),
  is_prepaid: z.boolean(),
  last_name: z.string(),
  line_items: z.array(OrderLineItemModal),
  note: z.string(),
  note_attributes: z.string().nullable(),
  payment_processor: z.string(),
  processed_at: IsoDatetimeStringModel.nullable(),
  scheduled_at: IsoDatetimeStringModel,
  shipped_date: IsoDatetimeStringModel.nullable(),
  shipping_address: ChargeAddressModel,
  shipping_date: IsoDatetimeStringModel.nullable(),
  shipping_lines: z.array(ShippingLineModel).optional(),
  shopify_cart_token: z.string().nullable(),
  shopify_customer_id: z.string(),
  shopify_id: z.number().nullable(),
  shopify_order_id: z.number().nullable(),
  shopify_order_number: z.number().nullable(),
  status: z.string(),
  subtotal_price: z.number(),
  tags: z.string(),
  tax_lines: z.array(z.unknown()),
  total_discounts: z.number(),
  total_duties: z.number(),
  total_line_items_price: z.number(),
  total_price: z.number(),
  total_refunds: z.number().nullable(),
  total_tax: z.number(),
  total_weight: z.number(),
  transaction_id: z.string(),
  type: z.string(),
  updated_at: IsoDatetimeStringModel,
});
