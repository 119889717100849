import deepmerge from 'deepmerge';
import i18n from 'i18next';
import 'intl-pluralrules';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en.json';

/**
 * On the 18th January 2023, we started migrating away from double-brackets as the syntax for interpolation to
 * single-brackets (`{{ data }}` -> `{ data }`).
 * As it's almost impossible to sync the deployment of this change with the deployment of the customcheckout repo,
 * we're creating this function that will translate double-brackets interpolation syntax to single-brackets.
 *
 * This method should be removed when the syntax migration is fully completed.
 */
function convertDoubleBracketToSingleBracket(translationMessages: Record<string, string>): Record<string, string> {
  return Object.keys(translationMessages).reduce((translations, key) => {
    translations[key] = translationMessages[key].replace(/\{\{(\s?\w+\s?)}}/g, '{$1}');
    return translations;
  }, {} as Record<string, string>);
}

function loadTranslationObject() {
  // Hackity hack hack. We created this object so some users could change the wording while we implemented the
  // final version of the translations.
  // It could be removed as soon as we completely migrate the users to the new translations.
  // Related ticket: https://recharge.atlassian.net/browse/BUN-887
  const tempRechargeTranslations = window.RechargeTranslations;

  const rechargeTranslations = window.ReCharge?.translations || {};

  if (tempRechargeTranslations && Object.keys(tempRechargeTranslations).length > 0) {
    return { en: { translation: convertDoubleBracketToSingleBracket(tempRechargeTranslations.en) } };
  }

  return { en: { translation: convertDoubleBracketToSingleBracket(rechargeTranslations) } };
}

export const setupI18n = () => {
  i18n.use(initReactI18next).init({
    resources: deepmerge(en, loadTranslationObject()),
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      prefix: '{',
      suffix: '}',
    },
  });
};
