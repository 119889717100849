import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { SkipChargePayload } from '~/types/api/request/charges';
import type { ChargeThemeEngineResponse, ThemeEngineCharge } from '~/types/api/response/charges';

import { post } from '~/common/rest';

export async function unskipCharge(
  context: ApiContext,
  { id, subscriptionIds }: Payloads.UnskipChargePayload
): Promise<ThemeEngineCharge> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/charges/${id}/unskip`);

  const payload = { subscription_ids: subscriptionIds.map(id => Number(id)) };
  const response = await post<ChargeThemeEngineResponse, SkipChargePayload>(endpointUrl, payload);
  return response.charge;
}
