import { ReactNode } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as styles from './Theme.css';
import { useCheckFlexGap } from '../utils/useCheckFlexGap';

export const Theme = ({ children }: { children: ReactNode }) => {
  const isFlexGapSupported = useCheckFlexGap();

  return (
    <div className={`recharge-theme ${styles.root}${isFlexGapSupported ? '' : ' flex-gap-not-supported'}`}>
      <SkeletonTheme
        borderRadius="calc(var(--recharge-corners-radius) / 2)"
        baseColor="var(--recharge-color-brand-85)"
        highlightColor="var(--recharge-color-brand-75)"
      >
        {children}
      </SkeletonTheme>
    </div>
  );
};

export const ThemeWithoutGapPolyfill = ({ children }: { children: ReactNode }) => (
  <div className={`recharge-theme ${styles.root}`}>
    <SkeletonTheme
      borderRadius="calc(var(--recharge-corners-radius) / 2)"
      baseColor="var(--recharge-color-brand-85)"
      highlightColor="var(--recharge-color-brand-75)"
    >
      {children}
    </SkeletonTheme>
  </div>
);
