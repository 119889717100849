import { get, post } from '~/common/rest';
import { CancelMembershipPayload } from '~/types/api/request/memberships';
import * as Payloads from '~/types/requests';
import type { MembershipsResponse } from '~/types/responses';
import { MembershipResponse } from '~/types/responses';
import type { ApiContext } from '~/types/strategies';

export async function getMemberships(context: ApiContext) {
  const schema = { memberships: { include: 'membership_program' } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const res = await get<MembershipsResponse>(endpointUrl);
  return res;
}

export async function getMembership(context: ApiContext, payload: Payloads.GetMembershipPayload) {
  const schema = { membership: { id: payload.id, include: 'membership_program' } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const { membership } = await get<{ membership: MembershipResponse }>(endpointUrl);

  return membership;
}

export async function getActiveMembership(context: ApiContext) {
  const schema = { memberships: { status: 'active' } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const { memberships } = await get<MembershipsResponse>(endpointUrl);

  return memberships && memberships.length > 0 ? memberships[0] : null;
}

export async function cancelMembership(context: ApiContext, { id, payload }: Payloads.CancelMembershipPayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/memberships/${id}/cancel`);
  const response = await post<{ membership: MembershipResponse }, CancelMembershipPayload>(endpointUrl, payload);
  return response.membership;
}
