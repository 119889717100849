import { useEffect, useState } from 'react';
import * as styles from './Toast.css';
import { Alert } from '../Alert';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { ToastPrivateInterface } from './ToastContext';
import { useToast } from './useToast';

const ToastAlert = ({ delay = 50, transient = true, loading, onClose, ...props }: ToastPrivateInterface) => {
  const [isActive, setIsActive] = useState(false);

  const shouldAutoClose = loading || transient !== undefined ? transient : props.tone === 'critical';

  useEffect(() => {
    const timer = setTimeout(() => setIsActive(true), delay);

    const autocloseTimer = setTimeout(async () => {
      if (!shouldAutoClose) {
        return;
      }

      setIsActive(false);
    }, 3000 + delay);

    return () => {
      clearTimeout(timer);

      clearTimeout(autocloseTimer);
    };
  }, [shouldAutoClose]);

  const handleTransitionEnd = () => {
    if (!isActive && onClose) {
      onClose();
    }
  };

  return (
    <Box className={styles.alert({ isActive })} onTransitionEnd={handleTransitionEnd}>
      <Alert {...props} emphasis={true} loading={loading} onClose={onClose} />
    </Box>
  );
};

export const Toast = () => {
  const { value, remove } = useToast();

  return (
    <Box className={styles.root}>
      <Stack space="large">
        {value.map(toast => (
          <ToastAlert {...toast} key={toast.id} onClose={() => remove(toast.id)} />
        ))}
      </Stack>
    </Box>
  );
};
