import type { ApiContext } from '~/types/strategies';
import type { Customer } from '~/types/responses';

import { get } from '~/common/rest';

type ThemeEngineResponse = { customer: Customer };

export async function getCustomer(context: ApiContext) {
  const schema = { customer: {} };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ThemeEngineResponse>(endpointUrl);
  return response.customer;
}
