import type { ThemeEngineProductsFilter } from '~/types/api/request/products';
import type { ShopifyThemeEngineProduct } from '~/types/api/response/products';
import type { ThemeEnginePlan } from '~/types/api/response/plans';
import type { ApiContext } from '~/types/strategies';

import { get } from '~/common/rest';
import {
  ensureArray,
  generateNextPageFilters,
  generatePreviousPageFilters,
  mapPlan,
  mapSubscriptionProductToPlans,
  normalizeProduct,
} from '../utils';
import { SettingsThemeEngineResponse } from '~/types/api/response/store';

type ProductsResponse = {
  meta: { products: { next: string | null; previous: string | null } };
  products: (ShopifyThemeEngineProduct & { plans: ThemeEnginePlan[] })[];
} & SettingsThemeEngineResponse;
export async function getProducts(context: ApiContext, filters: ThemeEngineProductsFilter = {}) {
  const schema = {
    settings: {},
    products: { ...filters, plans: { external_product_id: 'parent.shopify_product_id' } },
  };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ProductsResponse>(endpointUrl);

  const planMappingFn = response.settings.customer_portal.affinity_plans ? mapPlan : mapSubscriptionProductToPlans;
  const products = ensureArray(response.products).map(product => normalizeProduct(product, planMappingFn));

  return {
    products,
    next: response.meta?.products.next ? generateNextPageFilters(filters) : null,
    previous: response.meta?.products.previous ? generatePreviousPageFilters(filters) : null,
  };
}

export async function getProduct(context: ApiContext, filters: ThemeEngineProductsFilter) {
  const { products } = await getProducts(context, filters);

  if (products.length === 0) {
    return null;
  }
  return products[0];
}
