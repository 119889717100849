import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { AddressesThemeEngineResponse, AddressThemeEngineResponse } from '~/types/api/response/addresses';

import { get } from '~/common/rest';
import { ensureArray } from '../utils';

export async function getAddresses(context: ApiContext, payload: Payloads.GetAddressesPayload = {}) {
  const schema = { addresses: payload };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/request_objects`, params);

  const response = await get<AddressesThemeEngineResponse>(endpointUrl);
  return ensureArray(response.addresses);
}

export async function getAddress(context: ApiContext, { id }: Payloads.GetAddressPayload) {
  const schema = { address: { id } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/request_objects`, params);

  const response = await get<AddressThemeEngineResponse>(endpointUrl);
  const { address } = response;

  return Object.keys(address).length > 0 ? address : null;
}
