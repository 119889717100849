import { Children, ReactNode } from 'react';
import { Box } from '../Box';
import { ResponsiveValue } from '../Box/sprinkles.css';
import { vars } from '../theme.css';
import * as styles from './Stack.css';

export type Stack = {
  space?: ResponsiveValue<keyof typeof vars.size>;
  component?: 'div' | 'ol' | 'ul';
  children: ReactNode;
  // align: OptionalResponsiveValue<Align>;
};

export const Stack = ({ space = 'medium', component = 'div', children }: Stack) => {
  const isList = component === 'ol' || component === 'ul';
  const stackItemComponent = isList ? 'li' : 'div';

  return (
    <Box as={component} display="flex" flexDirection="column" gap={space}>
      {Children.map(children, child => {
        if (child || child === 0) {
          return (
            <Box className={styles.StackItem} as={stackItemComponent}>
              {child}
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};
