import { useEffect } from 'react';
import { useGetExperiences } from '../../../../../theme/src/api/useGetExperiences';
import { isExperiencesUpsellProduct } from '../../../../../theme/src/utils/experiences';
import { useRedirect } from '../../../hooks/useRedirect';

export const UpsellProductAutoRedirect = () => {
  const { redirect, isLoading: isLoadingRedirect } = useRedirect();

  const { data, isLoading } = useGetExperiences({
    use_flow: '1',
  });

  const experience = data?.experiences.find(isExperiencesUpsellProduct);

  useEffect(() => {
    if (!isLoading && !isLoadingRedirect && !experience) {
      redirect();
    }
  }, [experience, isLoading, isLoadingRedirect]);

  return null;
};
