import type { ThemeEngineSubscription } from '~/types/api/response/subscriptions';
import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

import { get } from '~/common/rest';
import {
  ensureArray,
  generateNextPageFilters,
  generatePreviousPageFilters,
  hasNextPageMetaless,
  hasPreviousPageMetaless,
  mapPlan,
  mapSubscriptionProductToPlans,
  normalizeProduct,
} from '../utils';
import { ShopifyThemeEngineProduct } from '~/types/api/response/products';
import { SettingsThemeEngineResponse } from '~/types/api/response/store';
import { PlansResponse } from '~/types/responses';
import { ThemeEnginePlan } from '~/types/api/response/plans';

export async function getSubscriptions(context: ApiContext, payload: Payloads.GetSubscriptionsPayload = {}) {
  interface SubscriptionsWithProductThemeEngineResponse extends SettingsThemeEngineResponse {
    subscriptions: (ThemeEngineSubscription & { product?: ShopifyThemeEngineProduct })[];
  }

  const schema = { settings: {}, subscriptions: payload };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<SubscriptionsWithProductThemeEngineResponse>(endpointUrl);

  const productIds = new Set();
  for (const delivery of ensureArray(response.subscriptions)) {
    productIds.add(delivery.shopify_product_id);
  }

  const plansSchema = { plans: { external_product_ids: Array.from(productIds).join(',') } };
  const plansParams = { schema: JSON.stringify(plansSchema) };
  const plansEndpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', plansParams);

  const plansByProduct: Record<string, ThemeEnginePlan[]> = {};
  const plansResponse = await get<PlansResponse>(plansEndpointUrl);
  for (const plan of plansResponse.plans) {
    if (!plansByProduct[plan.external_product_id.ecommerce]) {
      plansByProduct[plan.external_product_id.ecommerce] = [];
    }
    plansByProduct[plan.external_product_id.ecommerce].push(plan);
  }

  const planMappingFn = response.settings.customer_portal.affinity_plans ? mapPlan : mapSubscriptionProductToPlans;
  const subscriptions = ensureArray(response.subscriptions).map(subscription => ({
    ...subscription,
    product: subscription.product
      ? normalizeProduct(
          { ...subscription.product, plans: plansByProduct[subscription.shopify_product_id] },
          planMappingFn
        )
      : undefined,
  }));

  return {
    next: hasNextPageMetaless(payload, subscriptions) ? generateNextPageFilters(payload) : null,
    previous: hasPreviousPageMetaless(payload) ? generatePreviousPageFilters(payload) : null,
    subscriptions: subscriptions,
  };
}

export async function getSubscription(context: ApiContext, payload: Payloads.GetSubscriptionPayload) {
  const { subscriptions } = await getSubscriptions(context, payload);
  return subscriptions.length > 0 ? subscriptions[0] : null;
}
