import type { Customer } from '~/types/responses';
import type { ApiContext } from '~/types/strategies';
import type { UpdateCustomerPayload } from '~/types/requests';

import { post } from '~/common/rest';

type ThemeEngineResponse = { customer: Customer };

export const updateCustomer = async (context: ApiContext, { payload }: UpdateCustomerPayload) => {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/customer`);
  const response = await post<ThemeEngineResponse, UpdateCustomerPayload['payload']>(endpointUrl, payload);
  return response.customer;
};
