import { ApiContext } from '~/types/strategies';
import * as Payloads from '~/types/requests';
import { get } from '~/common/rest';
import { GiftResponse } from '~/types/responses';
import { generateMock } from '@anatine/zod-mock';
import { GiftModel } from '~/models/giftModel';

export async function getGift(context: ApiContext, payload: Payloads.GetGiftPayload) {
  const schema = { gifts: { id: payload.id } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  //   const { gift } = await get<{ gift: GiftResponse }>(endpointUrl);
  // MOCKED FOR NOW
  const { gift } = await new Promise<{ gift: GiftResponse }>((resolve, reject) => {
    console.info(endpointUrl);
    setTimeout(
      () =>
        resolve({
          gift: generateMock(GiftModel),
        }),
      2000
    );
  });
  return gift;
}
