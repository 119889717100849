import { ReactNode } from 'react';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import CheckCircleIcon from '../Iconography/check.svg';
import InfoIcon from '../Iconography/info.svg';
import RemoveIcon from '../Iconography/remove.svg';
import WarningIcon from '../Iconography/warning.svg';
import { Inline, InlineItem } from '../Inline/Inline';
import { Spinner } from '../Spinner';
import { Stack } from '../Stack';
import { Text } from '../Text';
import * as styles from './Alert.css';

export type Alert = styles.AlertVariants & {
  title?: string;
  message?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  onClose?: null | (() => void);
  closeLabel?: string;
};

export const Alert = function ({
  title,
  message,
  loading = false,
  tone = 'neutral',
  emphasis = false,
  children,
  icon,
  onClose,
  closeLabel = 'Close alert',
}: Alert) {
  const Icons = {
    neutral: InfoIcon,
    primary: InfoIcon,
    positive: CheckCircleIcon,
    critical: WarningIcon,
    caution: WarningIcon,
  };

  const MainIcon = Icons[tone || 'neutral'];

  return (
    <Box
      as="div"
      padding="large"
      className={`recharge-alert ${styles.alert({ tone, loading, emphasis })}`}
      role="alert"
      aria-live="polite"
    >
      <Stack space="small">
        <Inline space="small" align="between" wrap="nowrap" container="none">
          <Inline space="small" wrap="nowrap" container="none" alignY="top">
            <InlineItem shrink={0} grow={0}>
              {loading ? (
                <Spinner />
              ) : icon ? (
                icon
              ) : (
                <Icon>
                  <MainIcon />
                </Icon>
              )}
            </InlineItem>

            <InlineItem grow={1} shrink={1}>
              <Stack space="xsmall">
                {title && <Heading variant="h3">{title}</Heading>}
                {message && <Text variant="p3">{message}</Text>}
              </Stack>
            </InlineItem>
          </Inline>

          {onClose && (
            <InlineItem shrink={0}>
              <IconButton variant="secondary" label={closeLabel} onPress={onClose}>
                <RemoveIcon />
              </IconButton>
            </InlineItem>
          )}
        </Inline>

        {children}
      </Stack>
    </Box>
  );
};
