import { Product } from '@customer-portal/affinity-api';
import { useToast } from '@customer-portal/affinity-components/src/Toast/useToast';
import { useTranslation } from 'react-i18next';
import { useClaimExperienceMutation } from '../../../../../theme/src/api/useClaimExperienceMutation';
import {
  useGetDeliverySchedule,
  useInvalidateGetDeliverySchedule,
} from '../../../../../theme/src/api/useGetDeliverySchedule';

export const useClaimExperience = () => {
  const { t } = useTranslation();
  const { invalidate } = useInvalidateGetDeliverySchedule();
  const { mutateAsync, isLoading } = useClaimExperienceMutation();
  const { dispatch } = useToast();
  const { data: schedules } = useGetDeliverySchedule();

  const handleClaimExperience = async (
    claimId: number,
    selectedVariantId: Product['variants'][number]['shopify_id'],
    productName: string
  ) => {
    if (!schedules) {
      return;
    }

    const nextSchedule = schedules[0];
    const addressId = nextSchedule.orders[0].subscription.address_id;

    await mutateAsync({
      claim_id: String(claimId),
      variant_id: String(selectedVariantId),
      address_id: String(addressId),
    });

    invalidate();

    dispatch({ tone: 'positive', message: t('notifications_product_added', { productName }) });
  };

  return { handleClaimExperience, isLoading };
};
