import { createContext, ReactNode, useCallback, useContext, useRef } from 'react';
import { FocusScope, OverlayContainer, OverlayProvider, useDialog, useModal, useOverlay } from 'react-aria';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Inline } from '../Inline';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { Theme } from '../Theme';
import { usePreventScroll } from '../utils/usePreventScroll';
import { useModalProps_DEPRECATED } from '../Modal';
import * as styles from './View.css';
import { ActionLink } from '../ActionLink';
import { Icon } from '../Icon';
import { Iconography } from '../Iconography';
import { useCheckFlexGap } from '../utils/useCheckFlexGap';

export interface ViewProps extends useModalProps_DEPRECATED {
  id?: string;
  children: ReactNode;
  closeLabel: string;
  context?: ReactNode;
  isDismissable?: boolean;
  role?: 'dialog' | 'alertdialog';
  onClose?: () => void;
}

const ViewContext = createContext<{ onClose?: ViewProps['close'] }>({});
ViewContext.displayName = 'ViewContext';

const useViewContext = () => useContext(ViewContext);

export const ViewHeadingWrapper = ({ children }: { children: ReactNode }) => <Stack space="xsmall">{children}</Stack>;

export const ViewHeading = ({ primary, secondary }: { primary?: string; secondary?: string }) => (
  <Box data-testid="view-heading-wrapper">
    <Inline space="xxxlarge" alignY="center" align={{ mobile: 'between', desktop: 'left' }}>
      {primary && <Heading variant="h3">{primary}</Heading>}
      {secondary && <Text variant="p4">{secondary}</Text>}
    </Inline>
  </Box>
);

const ViewHeader = ({ context }: Pick<ViewProps, 'context'>) => {
  const isGapSupported = useCheckFlexGap();

  return (
    <Box className={styles.header}>
      <Box className={styles.headerContent({ isGapSupported })}>
        <Stack space="medium">{context}</Stack>
      </Box>
    </Box>
  );
};

export const CloseViewButton = ({ children }: { children: ReactNode }) => {
  const { onClose } = useViewContext();

  return (
    <Box className={styles.backButton}>
      <ActionLink tone="neutral" onPress={onClose}>
        <Box backgroundColor="brand85" borderRadius="radius">
          <Icon inner="medium">
            <Iconography.ChevronLeftIcon />
          </Icon>
        </Box>
        <Box color="brand">
          <Text variant="p3">{children}</Text>
        </Box>
      </ActionLink>
    </Box>
  );
};

const ViewContainer = (props: ViewProps) => {
  const { children, isOpen, context } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);

  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, ref);

  usePreventScroll();

  return (
    <OverlayContainer>
      <Theme>
        <Box className={styles.root({ isOpen })} {...underlayProps}>
          <FocusScope contain restoreFocus autoFocus>
            <Box {...overlayProps} {...dialogProps} {...titleProps} {...modalProps} ref={ref}>
              <ViewHeader context={context} />
              <Box display="flex" justifyContent="center">
                <Box className={styles.container}>{children}</Box>
              </Box>
            </Box>
          </FocusScope>
        </Box>
      </Theme>
    </OverlayContainer>
  );
};

export const View = (props: ViewProps) => {
  const { isRendered, isDismissable = true } = props;

  const handleClose = useCallback(() => props.close(), [props.close]);

  if (!isRendered) {
    return null;
  }

  return (
    <OverlayProvider>
      <ViewContext.Provider value={{ onClose: handleClose }}>
        <ViewContainer {...props} onClose={handleClose} isDismissable={isDismissable}>
          {props.children}
        </ViewContainer>
      </ViewContext.Provider>
    </OverlayProvider>
  );
};
