import { z } from 'zod';
import { DateTime } from 'luxon';

export const PropertyModel = z.object({
  name: z.string(),
  value: z.string(),
});

export const BooleanLikeModel = z.union([
  z.boolean(),
  z.union([z.literal('true'), z.literal('false')]),
  z.union([z.literal(0), z.literal(1)]),
  z.union([z.literal('0'), z.literal('1')]),
]);

export const IsoDatetimeStringModel = z.string().refine(val => DateTime.fromISO(val).isValid);
