import { z } from 'zod';
import { BooleanLikeModel, IsoDatetimeStringModel, PropertyModel } from '~/models/commonModels';
import { BundleSelectionsModel } from './bundleModel';

export const ShippingIntervalUnit = z.union([z.literal('day'), z.literal('week'), z.literal('month')]);

export const SubscriptionStatusModel = z.union([
  z.literal('ACTIVE'),
  z.literal('CANCELLED'),
  z.literal('EXPIRED'),
  z.literal('ONETIME'),
]);

export const SubscriptionModel = z.object({
  id: z.number(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,

  charge_interval_frequency: z.number(),
  expire_after_specific_number_of_charges: z.number().nullable(),
  order_day_of_month: z.number().nullable(),
  order_day_of_week: z.number().nullable(),
  order_interval_frequency: z.string(),
  order_interval_frequency_options: z.array(z.string()),
  order_interval_unit: ShippingIntervalUnit,

  address_id: z.number(),
  cancellation_reason: z.string().nullable(),
  cancellation_reason_comments: z.string().nullable(),
  cancelled_at: z.string().nullable(),
  charge_delay: z.number().nullable(),
  customer_id: z.number(),
  cutoff_day_of_month_before_and_after: z.number().nullable(),
  cutoff_day_of_week_before_and_after: z.number().nullable(),
  email: z.string(),
  first_charge_date: z.string().nullable(),
  has_queued_charges: BooleanLikeModel,
  is_prepaid: z.boolean(),
  is_skippable: z.boolean(),
  is_swappable: z.boolean(),
  locked_pending_charge_id: BooleanLikeModel,
  max_retries_reached: BooleanLikeModel,
  next_charge_scheduled_at: z.string().nullable(),
  presentment_currency: z.string(),
  price: z.number(),
  product_title: z.string(),
  properties: z.array(PropertyModel),
  quantity: z.number(),
  recharge_product_id: z.number(),
  shopify_product_id: z.number(),
  shopify_variant_id: z.number(),
  sku: z.string().nullable(),
  sku_override: z.boolean(),
  status: SubscriptionStatusModel,
  variant_title: z.string(),

  bundle_product: z.any().nullable().optional(),
  bundle_selections: BundleSelectionsModel.nullable().optional(),
});
