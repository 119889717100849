import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ChargeThemeEngineResponse, ThemeEngineCharge } from '~/types/api/response/charges';
import type { ThemeEngineSubscription } from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';
import { skipCharge, getNextChargeForSubscription, getCharges } from '../charges';
import { ThemeEngineChargeAddressSkipPayload } from '~/types/api/request/orders';

function updateOnetimeChargeDate(context: ApiContext, onetimeId: string, nextChargeDate: string) {
  const url = context.urlGenerator(context.baseUrl, context.token, `/onetimes/${onetimeId}/set_next_charge_date`);
  const payload = { next_charge_scheduled_at: nextChargeDate };
  return post<{ onetime: ThemeEngineSubscription }>(url, payload).then(response => response.onetime);
}

type NextChargeResponse = { chargeId: string; charge: ThemeEngineCharge };
function getNextCharge(context: ApiContext, chargeId: string, subscriptionId: string) {
  return getNextChargeForSubscription(context, subscriptionId).then(charge => ({ chargeId, charge }));
}

/**
 * Skips the subscriptions that belong to the charge, and updates the next
 * charge date for the one times belonging to the charge.
 * @param context The context of the Strategy
 * @param payload
 * @returns The bulk response data containing the new charge information.
 */
export async function skipOrder(context: ApiContext, { chargeIds, onetimeDate }: Payloads.SkipOrderPayload) {
  // We obtain full data of each one of the charges
  const charges = await getCharges(context, { ids: chargeIds.join(',') });

  // Group the subscriptions by Charge ID
  const subscriptionsByCharge: Record<string, string[]> = {};
  for (const charge of charges) {
    const chargeId = charge.id.toString();
    const subscriptionLineItems = charge.line_items
      .filter(lineItem => lineItem.type === 'SUBSCRIPTION' && lineItem.subscription_id)
      .map(lineItem => lineItem.subscription_id!.toString());

    if (subscriptionLineItems.length > 0) {
      subscriptionsByCharge[chargeId] = subscriptionLineItems;
    }
  }

  // Group the onetimes by Charge ID
  const onetimesByCharge: Record<string, string[]> = {};
  for (const charge of charges) {
    const chargeId = charge.id.toString();
    const onetimeLineItems = charge.line_items
      .filter(lineItem => lineItem.type === 'ONETIME' && lineItem.subscription_id)
      .map(lineItem => lineItem.subscription_id!.toString());

    onetimesByCharge[chargeId] = onetimeLineItems;
  }

  // Skip the subscriptions of a Charge
  const skipChargesSuccessful: string[] = [];
  const skipChargesFailures: string[] = [];
  for (const chargeId in subscriptionsByCharge) {
    const subscriptionIds = subscriptionsByCharge[chargeId];

    try {
      await skipCharge(context, { id: chargeId, subscriptionIds });
      skipChargesSuccessful.push(chargeId);
    } catch {
      skipChargesFailures.push(chargeId);
    }
  }

  // Remove failed charges
  for (const failedChargeId of skipChargesFailures) {
    delete subscriptionsByCharge[failedChargeId];
    delete onetimesByCharge[failedChargeId];
  }

  // Get the next charge for the first subscription of each successful charge
  const subscriptionCharge = Object.entries(subscriptionsByCharge).map(([cId, subsIds]) => [cId, subsIds[0]]);
  const nextCharges = subscriptionCharge.map(([cId, subId]) => getNextCharge(context, cId, subId));

  const nextChargesResult = await Promise.allSettled(nextCharges);
  const nextChargesSuccessful = nextChargesResult
    .filter((result): result is PromiseFulfilledResult<NextChargeResponse> => result.status === 'fulfilled')
    .map(result => result.value);

  const nextChargeByInitialCharge: Record<string, ThemeEngineCharge> = {};
  for (const charge of nextChargesSuccessful) {
    nextChargeByInitialCharge[charge.chargeId] = charge.charge;
  }

  // If we don't want to skip one times, return early
  if (!onetimeDate) {
    return {
      success: skipChargesSuccessful,
      failure: skipChargesFailures,
      charges: nextChargeByInitialCharge,
    };
  }

  // Change charge dates of onetimes
  for (const chargeId in onetimesByCharge) {
    const onetimeIds = onetimesByCharge[chargeId];

    for (const onetimeId of onetimeIds) {
      try {
        await updateOnetimeChargeDate(context, onetimeId, onetimeDate);
      } catch {
        console.error(`Failed to update charge date for onetime: ${onetimeId}`);
      }
    }
  }

  return {
    success: skipChargesSuccessful,
    failure: skipChargesFailures,
    charges: nextChargeByInitialCharge,
  };
}

/**
 * Skips a future order. A future order is described as a scheduled order
 * without a charge.
 * @param context The context of the Strategy
 * @param addressId The address ID of the subscriptions to be skipped in the future
 * @param subscriptionIds The subscription IDs to be skipped in the future
 * @param date The date to be skipped
 */
export async function skipFutureOrder(
  context: ApiContext,
  { addressId, subscriptionIds, date }: Payloads.SkipFutureOrderPayload
) {
  const payload = { subscription_ids: subscriptionIds.map(id => Number(id)), date };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses/${addressId}/charges/skip`);

  const response = await post<ChargeThemeEngineResponse, ThemeEngineChargeAddressSkipPayload>(endpointUrl, payload);
  return response.charge;
}
