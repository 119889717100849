import { DeliverySchedule, DeliveryScheduleOrder, Order, Subscription } from '@customer-portal/affinity-api';

export const isProjectedShipment = (shipmentType: DeliverySchedule['orders'][0]['shipment_type']) =>
  shipmentType === 'PREPAY PROJECTED SHIPMENT' || shipmentType === 'PREPAID QUEUED_SHIPMENT';

export const hasMinimumOrderAmountPerSubscription = (minimumOrderCount: number, numOfOrders: number) =>
  numOfOrders >= minimumOrderCount;

export const setBundleProductDataToEachOrderSubscription = (
  orders: DeliverySchedule['orders'],
  subscriptions: Subscription[] = []
) => {
  const subscriptionsHashTable = subscriptions.reduce<Record<number, Subscription>>((prev, current) => {
    prev[current.id] = current;
    return prev;
  }, {});

  return orders.map(order => ({
    ...order,
    subscription: {
      ...order.subscription,
      bundle_product:
        subscriptionsHashTable[order.subscription.id]?.bundle_product ?? order.subscription.bundle_product,
      bundle_selections:
        subscriptionsHashTable[order.subscription.id]?.bundle_selections ?? order.subscription.bundle_selections,
    },
  }));
};

export const hasMinimumOrderAmountPerSub = (minimumOrderCount: number, numOfOrders: number) =>
  minimumOrderCount === 0 || numOfOrders >= minimumOrderCount;

/** Returns a type safe boolean of whether we are a true order or an empty object */
export function isOrder(deliveryOrder: DeliveryScheduleOrder['order']): deliveryOrder is Order {
  return !!(deliveryOrder as unknown as Order)['id'];
}
