import type { Address } from '~/types/responses';
import type { ApiContext } from '~/types/strategies';
import type * as Payloads from '~/types/requests';
import type { AddressThemeEngineResponse } from '~/types/api/response/addresses';
import type { ThemeEngineCreateAddressPayload } from '~/types/api/request/addresses';

import { post } from '~/common/rest';

export async function createAddress(context: ApiContext, payload: Payloads.CreateAddressPayload): Promise<Address> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses`);
  const response = await post<AddressThemeEngineResponse, ThemeEngineCreateAddressPayload>(endpointUrl, payload);
  return response.address;
}
