import type { ApiContext } from '~/types/strategies';
import type { MembershipConditionsResponse } from '~/types/responses';
import { get } from '~/common/rest';

export async function getMembershipConditions(context: ApiContext) {
  const schema = {
    membership_program_conditions: {
      include: 'plan',
      product: {
        shopify_product_id: 'parent.include.plan.external_product_id.ecommerce',
      },
    },
  };

  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  return get<MembershipConditionsResponse>(endpointUrl);
}
