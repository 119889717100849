import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { OnetimeThemeEngineResponse } from '~/types/api/response/onetimes';
import type { ThemeEngineUpdateOnetimePayload } from '~/types/api/request/onetimes';

import { post } from '~/common/rest';

export async function updateOnetime(context: ApiContext, { id, payload }: Payloads.UpdateOnetimePayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/onetimes/${id}`);
  const response = await post<OnetimeThemeEngineResponse, ThemeEngineUpdateOnetimePayload>(endpointUrl, payload);
  return response.onetime;
}
