import { z } from 'zod';
import { BooleanLikeModel, IsoDatetimeStringModel } from './commonModels';
import { PaymentMethodModel } from './paymentMethodModel';

export const CustomerModel = z.object({
  id: z.string(),

  accepts_marketing: BooleanLikeModel,
  can_add_payment_method: z.boolean(),
  created_at: IsoDatetimeStringModel,
  deleted_at: IsoDatetimeStringModel.nullable(),
  email: z.string().email().nullable(),
  external_sync: z.boolean(),
  first_charge_processed_at: IsoDatetimeStringModel.nullable(),
  first_name: z.string().nullable(),
  has_card_error_in_dunning: z.boolean(),
  hash: z.string().nullable(),
  include: z.object({ payment_methods: z.array(PaymentMethodModel) }),
  last_name: z.string().nullable(),
  number_active_subscriptions: z.number(),
  number_subscriptions: z.number(),
  phone: z.string().nullable(),
  shopify_customer_id: z.string().nullable(),
  tax_exempt: z.boolean(),
  updated_at: IsoDatetimeStringModel,
});

export const CustomerCreditRewardCredit = z.object({
  id: z.number(),
  available_balance: z.string(),
  created_at: IsoDatetimeStringModel,
  currency_code: z.string(),
  expires_at: IsoDatetimeStringModel.nullable(),
});

export const CreditBalance = z.object({
  type: z.string(),
  available_balance: z.string(),
  total_spent: z.string(),
});

export const CustomerCreditSummaryModel = z.object({
  customer_id: z.number(),
  total_available_balance: z.string(),
  includes: z
    .object({
      reward_credit: z.array(CustomerCreditRewardCredit),
    })
    .nullable(),
  balances: z.array(CreditBalance).nullable(),
});
