import type { ApiContext } from '~/types/strategies';
import type * as Payloads from '~/types/requests';
import type { OnetimeThemeEngineResponse } from '~/types/api/response/onetimes';
import type { ThemeEngineAddOnetimePayload } from '~/types/api/request/onetimes';

import { post } from '~/common/rest';

export async function addOnetime(context: ApiContext, payload: Payloads.AddOnetimePayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/onetimes`);
  const response = await post<OnetimeThemeEngineResponse, ThemeEngineAddOnetimePayload>(endpointUrl, payload);
  return response.onetime;
}
