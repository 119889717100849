import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { CancelSubscriptionPayload } from '~/types/api/request/subscriptions';
import type { ThemeEngineSubscription, SubscriptionThemeEngineResponse } from '~/types/api/response/subscriptions';

import { post } from '~/common/rest';

export async function cancelSubscription(
  context: ApiContext,
  { id, payload }: Payloads.CancelSubscriptionPayload
): Promise<ThemeEngineSubscription> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/subscriptions/${id}/cancel`);
  const response = await post<SubscriptionThemeEngineResponse, CancelSubscriptionPayload>(endpointUrl, payload);
  return response.subscription;
}
