import { z } from 'zod';

const BundleVariantOptionSourceModel = z.object({
  bundle_variant_id: z.number(),
  id: z.number(),
  option_source_id: z.string(),
  position: z.number().optional(),
  quantity_max: z.number().nullable(),
  quantity_min: z.number().nullable(),
  source_platform: z.union([z.string(), z.literal('shopify')]),
  store_id: z.number(),
});

const BundleVariantSelectionDefaultModel = z.object({
  id: z.number().optional(),
  quantity: z.number(),
  external_variant_id: z.string(),
});

const BundleVariantOptionValueModel = z.object({
  id: z.number(),
  label: z.string(),
});

const BundleVariantModel = z.object({
  enabled: z.boolean(),
  external_variant_id: z.string(),
  id: z.number().optional(),
  items_count: z.number(),
  option_sources: z.array(BundleVariantOptionSourceModel),
  position: z.number().optional(),
  selection_defaults: z.array(BundleVariantSelectionDefaultModel),
  title: z.string(),
  option_values: z.array(BundleVariantOptionValueModel),
});

const SettingsModel = z.object({
  collectionId: z.string(),
  collectionHandle: z.string(),
  enabled: z.boolean(),
});

const AddToCartCallbackSettingsModel = z.object({
  type: z.union([z.literal('none'), z.literal('callback'), z.literal('redirect')]),
  value: z.string(),
});

const TemplateSettingsModel = z.object({
  multiStep: z.any().optional(),
  onePage: z.any(),
});

const WidgetVisibilityModel = z.object({ signup: z.boolean(), portal: z.boolean() });

const LayoutSettingsModel = z.object({
  addons: SettingsModel.optional(),
  addToCartCallback: AddToCartCallbackSettingsModel.optional(),
  collapsibleSections: z.boolean(),
  crossSells: SettingsModel.optional(),
  defaultFrequency: z.string().optional(),
  defaultVariantId: z.string().optional(),
  description: z.string().optional(),
  filters: z.array(z.string()),
  learnMoreModal: z.boolean(),
  published: z.boolean().optional(),
  showVariants: z.boolean().optional(),
  subscribeAndSave: z.boolean().optional(),
  template: z.union([z.literal('multi-step'), z.literal('one-page'), z.literal('custom'), z.literal('none')]),
  templateSettings: TemplateSettingsModel,
  title: z.string().optional(),
  visibility: WidgetVisibilityModel.optional(),
});

export const BundleProductModel = z.object({
  customization_window_disabled_message: z.string().nullable(),
  customization_window: z.number().nullable(),
  default_bundle_variant_id: z.number(),
  description: z.string().nullable(),
  external_product_id: z.string(),
  id: z.number(),
  is_customizable: z.boolean(),
  layout_settings_version: z.literal('2021-11'),
  max_quantity_per_variant: z.number().nullable(),
  reset_box_contents: z.boolean(),
  title: z.string(),
  updated_at: z.string().optional(),
  created_at: z.string().optional(),
  layout_settings: LayoutSettingsModel.optional(),
  variants: z.array(BundleVariantModel),
  price_rule: z.union([z.literal('fixed'), z.literal('dynamic')]).nullable(),
});

const BundleSelectionModel = z.object({
  quantity: z.number(),
  collection_id: z.number(),
  external_product_id: z.number(),
  external_variant_id: z.number(),
  price: z.number(),
});

export const BundleSelectionsModel = z.object({
  external_variant_id: z.number(),
  selections: z.array(BundleSelectionModel),
});
