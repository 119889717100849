import { GetExperiencePayload } from '@customer-portal/affinity-api';
import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const EXPERIENCES_QUERY_KEY = 'experiences';

export function useGetExperiences(params: GetExperiencePayload = {}, options = { enabled: true }) {
  const { api } = useApi();

  return useQuery([EXPERIENCES_QUERY_KEY, params], () => api.getExperiences(params), options);
}
