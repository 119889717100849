import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { portalPermissionControls } from '../utils/portalPermissionControls';

export const useListenToRefreshEvent = () => {
  const queryClient = useQueryClient();
  const { allow_extensions } = portalPermissionControls();

  useEffect(() => {
    const refresh = () => queryClient.resetQueries();

    if (allow_extensions) {
      document.addEventListener('Affinity:refresh', refresh);
    }

    return () => {
      document.removeEventListener('Affinity:refresh', refresh);
    };
  }, []);
};
