import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type {
  ThemeEngineCharge,
  ChargeThemeEngineResponse,
  ChargesThemeEngineResponse,
} from '~/types/api/response/charges';

import { get } from '~/common/rest';

export async function getCharges(context: ApiContext, payload: Payloads.GetChargesPayload = {}) {
  const schema = { charges: payload };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ChargesThemeEngineResponse>(endpointUrl);
  return response.charges;
}

export async function getCharge(
  context: ApiContext,
  payload: Payloads.GetChargesPayload = {}
): Promise<ThemeEngineCharge> {
  const schema = { charge: payload };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ChargeThemeEngineResponse>(endpointUrl);
  return response.charge;
}

export async function getNextChargeForSubscription(
  context: ApiContext,
  subscriptionId: string
): Promise<ThemeEngineCharge | null> {
  const schema = { charges: { subscription_id: subscriptionId, status: 'QUEUED' } };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<ChargesThemeEngineResponse>(endpointUrl);

  const nextCharge = response.charges[0];
  return nextCharge || null;
}
