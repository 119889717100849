import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineCharge } from '~/types/api/response/charges';

import { getCharges, unskipCharge } from '../charges';

export async function unskipOrder(context: ApiContext, { chargeIds }: Payloads.UnskipOrder) {
  // We obtain full data of each one of the charges
  const charges = await getCharges(context, { ids: chargeIds.join(',') });
  const successfulUnskips: ThemeEngineCharge[] = [];
  const failedUnskipIds: string[] = [];

  // Filter SKIPPED charges only
  const skippedCharges = charges.filter(charge => charge.status === 'SKIPPED');

  // Get the subscription IDs by charge
  const subscriptionIdsByCharge: Record<string, number[]> = {};
  for (const charge of skippedCharges) {
    const chargeId = charge.id.toString();
    const subscriptionIds = charge.line_items
      .filter(lineItem => lineItem.type === 'SUBSCRIPTION' && lineItem.subscription_id)
      .map(lineItem => lineItem.subscription_id!);

    if (subscriptionIds.length > 0) {
      subscriptionIdsByCharge[chargeId] = subscriptionIds;
    }
  }

  // Unskip each charge individually
  // We can't do a parallelization here as the unskip have a lock, if we try to
  // skip two charges in the same address at the same time, we'll get a 429
  // status code.
  for (const chargeId in subscriptionIdsByCharge) {
    const subscriptionIds = subscriptionIdsByCharge[chargeId].map(id => String(id));

    try {
      const unskippedCharge = await unskipCharge(context, { id: chargeId, subscriptionIds });
      successfulUnskips.push(unskippedCharge);
    } catch {
      failedUnskipIds.push(chargeId);
    }
  }

  const chargesLookup: Record<string, ThemeEngineCharge> = {};
  for (const charge of successfulUnskips) {
    const chargeId = charge.id.toString();
    chargesLookup[chargeId] = charge;
  }

  return {
    success: successfulUnskips.map(charge => charge.id.toString()),
    failure: failedUnskipIds,
    charges: chargesLookup,
  };
}
