import { MouseEvent, useState } from 'react';
import { awaitTimeout } from '../utils/awaitTimeout';
import { PressEvent } from '@react-types/shared';

interface UseModalParams {
  onOpen?: () => void;
  onClose?: () => void;
  defaultOpen?: boolean;
}

/**
 * @deprecated
 */
export interface useModalProps_DEPRECATED {
  isOpen: boolean;
  isRendered: boolean;
  open: (queryParam?: ViewActionArgs) => Promise<void>;
  close: (queryParam?: ViewActionArgs) => Promise<void>;
  isRenderedLookup: (queryParam: string) => boolean;
  isOpenLookup: (queryParam: string) => boolean;
}

type ViewActionArgs = string | PressEvent | MouseEvent;

/**
 * @deprecated
 */
export const useModal_DEPRECATED = ({
  onOpen,
  onClose,
  defaultOpen,
}: UseModalParams = {}): useModalProps_DEPRECATED => {
  const [renderedLookup, setRenderedLookup] = useState<Record<string, boolean>>({});
  const [openLookup, setOpenLookup] = useState<Record<string, boolean>>({});
  const [isRendered, setIsRendered] = useState(!!defaultOpen);
  const [isOpen, setOpen] = useState(!!defaultOpen);

  const close = async (queryParam?: ViewActionArgs) => {
    setOpen(false);

    setOpenLookup(oldLookup => {
      if (!queryParam || typeof queryParam !== 'string') {
        return oldLookup;
      }

      return { ...oldLookup, [queryParam]: false };
    });

    await awaitTimeout(300);

    setIsRendered(false);

    setRenderedLookup(oldLookup => {
      if (!queryParam || typeof queryParam !== 'string') {
        return oldLookup;
      }

      return { ...oldLookup, [queryParam]: false };
    });

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const open = async (queryParam?: ViewActionArgs) => {
    setIsRendered(true);

    setRenderedLookup(oldLookup => {
      if (!queryParam || typeof queryParam !== 'string') {
        return oldLookup;
      }

      return { ...oldLookup, [queryParam]: true };
    });

    await awaitTimeout(50);

    setOpen(true);

    setOpenLookup(oldLookup => {
      if (!queryParam || typeof queryParam !== 'string') {
        return oldLookup;
      }

      return { ...oldLookup, [queryParam]: true };
    });

    if (typeof onOpen === 'function') {
      onOpen();
    }
  };

  const isRenderedLookup = (queryParam: string) => renderedLookup[queryParam];
  const isOpenLookup = (queryParam: string) => openLookup[queryParam];

  return {
    open,
    close,
    isOpen,
    isRendered,
    isRenderedLookup,
    isOpenLookup,
  };
};
