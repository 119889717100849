import { Button, Inline } from '@customer-portal/affinity-components';
import { useRedirect } from '../../hooks/useRedirect';

export const Continue = () => {
  const { redirect } = useRedirect();

  return (
    <Inline align="center">
      <Button onPress={redirect}>Continue</Button>
    </Inline>
  );
};
