import { PressEvent } from '@react-types/shared';
import { ButtonHTMLAttributes, forwardRef, ReactNode, useRef } from 'react';
import { FocusRing, useButton, VisuallyHidden } from 'react-aria';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Focus } from '../utils/focus.css';
import type { TestAttributes } from '../types';
import * as styles from './IconButton.css';

export type IconButton = styles.IconButtonButtonVariants &
  TestAttributes &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
    inner?: Icon['inner'];
    href?: string;
    children: JSX.Element | ReactNode;
    disabled?: boolean;
    onPress?: (e: PressEvent) => void;
  };

export const IconButton = forwardRef<HTMLElement, IconButton>(
  ({ label, inner = 'xsmall', variant = 'primary', href, children, disabled = false, ...props }, ref) => {
    const elementType = href ? 'a' : 'span';

    const innerRef = useRef(null);

    const { buttonProps } = useButton(
      {
        isDisabled: disabled,
        elementType,
        'aria-label': props['aria-label'],
        onPress: props.onPress,
      },
      innerRef
    );

    return (
      <FocusRing focusRingClass={Focus}>
        <Box
          {...buttonProps}
          aria-current={props['aria-current']}
          aria-label={buttonProps['aria-label']}
          ref={ref}
          as={elementType}
          href={href}
          className={styles.IconButton({ variant, disabled })}
          data-testid={props['data-testid']}
        >
          <Icon inner={inner}>{children}</Icon>
          <VisuallyHidden>{label}</VisuallyHidden>
        </Box>
      </FocusRing>
    );
  }
);

IconButton.displayName = 'IconButton';
