import { z } from 'zod';
import { IsoDatetimeStringModel, PropertyModel } from '~/models/commonModels';
import { ProductImageModel } from '~/models/productModel';

export const ChargeStatusModel = z.union([
  z.literal('SUCCESS'),
  z.literal('ERROR'),
  z.literal('QUEUED'),
  z.literal('SKIPPED'),
  z.literal('REFUNDED'),
  z.literal('PARTIALLY_REFUNDED'),
  z.literal('PENDING_MANUAL_PAYMENT'),
  z.literal('PENDING'),
]);

export const ChargeErrorTypeModel = z.union([
  z.literal('BILLING_ADDRESS_ERROR'),
  z.literal('BRAINTREE_ERROR_GENERAL'),
  z.literal('BUYER_CANCELED_PAYMENT_METHOD'),
  z.literal('CARD_DECLINED'),
  z.literal('CARD_ERROR_GENERAL'),
  z.literal('CARD_EXPIRED'),
  z.literal('CARD_UPDATED_NOW_PENDING_NEXT_ATTEMPT'),
  z.literal('CARD_ZIPCODE_FAILED_VALIDATION'),
  z.literal('CARDNUMBER_INCORRECT'),
  z.literal('CLOSED_MAX_RETRIES_REACHED'),
  z.literal('CUSTOMER_NEEDS_TO_UPDATE_CARD'),
  z.literal('EMPTY_CHARGE_ENTRY'),
  z.literal('EXPIRED_PAYMENT_METHOD'),
  z.literal('FRAUD'),
  z.literal('INSUFFICIENT_FUNDS'),
  z.literal('INSUFFICIENT_INVENTORY'),
  z.literal('INVALID_CUSTOMER_BILLING_AGREEMENT'),
  z.literal('INVALID_PAYMENT_METHOD'),
  z.literal('PAYMENT_METHOD_DECLINED'),
  z.literal('PAYPAL_ERROR_GENERAL'),
  z.literal('PENDING_SCA_AUTHENTICATION'),
  z.literal('PROCESSING_ERROR'),
  z.literal('PURCHASE_TYPE_NOT_SUPPORTED_BY_CARD'),
  z.literal('SHIPPING_RATE_ERROR'),
  z.literal('SHOPIFY_REJECTED'),
  z.literal('UNEXPECTED_ERROR'),
  z.literal('PAYMENT_PROVIDER_IS_NOT_ENABLED'),
  // Variant level
  z.literal('VARIANT_DOES_NOT_EXIST'),
  z.literal('VARIANT_OPTION_DOES_NOT_EXIST'),
  // Generic unknown error
  z.literal('ERROR'),
]);

export const ChargeClientDetailsModel = z.object({
  browser_ip: z.string(),
  user_agent: z.string(),
});

export const ChargeAddressModel = z.object({
  address1: z.string(),
  address2: z.string().nullable(),
  city: z.string(),
  company: z.string().nullable(),
  country: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string().nullable(),
  province: z.string(),
  zip: z.string(),
});

export const ChargeDiscountModel = z.object({
  amount: z.string(),
  code: z.string(),
  recharge_discount_id: z.number(),
  type: z.union([z.literal('percentage'), z.literal('fixed_amount')]),
});

export const ChargeLineItemModel = z.object({
  images: ProductImageModel,
  price: z.string(),
  original_price: z.string(),
  properties: z.array(PropertyModel),
  shopify_product_id: z.string(),
  shopify_variant_id: z.string(),
  sku: z.string(),
  subscription_id: z.number().nullable(),
  title: z.string(),
  type: z.union([z.literal('SUBSCRIPTION'), z.literal('ONETIME')]),
  variant_title: z.string(),
  quantity: z.number(),
});

export const ShippingLineModel = z.object({
  code: z.string(),
  description: z.string(),
  price: z.string(),
  source: z.string(),
  title: z.string(),
});

export const Transaction = z.object({
  processor_name: z.string(),
  payment_method_id: z.number(),
  amount: z.string(),
  created_at: IsoDatetimeStringModel,
  external_transaction_id: z.string(),
  is_primary: z.boolean(),
  kind: z.string(),
});

export const ChargeInclude = z
  .object({
    transactions: z.array(Transaction).optional(),
  })
  .optional();

export const ChargeModel = z.object({
  id: z.number(),
  created_at: IsoDatetimeStringModel,
  updated_at: IsoDatetimeStringModel,

  address_id: z.number(),
  billing_address: ChargeAddressModel,
  client_details: ChargeClientDetailsModel,
  currency: z.string(),
  customer_hash: z.string(),
  customer_id: z.number(),
  discount_codes: z.array(ChargeDiscountModel).nullable(),
  error: z.string().optional(),
  error_type: ChargeErrorTypeModel.optional(),
  email: z.string().email(),
  first_name: z.string(),
  has_uncommited_changes: z.boolean(),
  include: ChargeInclude,
  last_name: z.string(),
  line_items: z.array(ChargeLineItemModel),
  note: z.string().nullable(),
  note_attributes: z.array(PropertyModel),
  processed_at: IsoDatetimeStringModel.nullable(),
  processor_name: z.union([z.literal('shopify_payments'), z.string()]),
  scheduled_at: IsoDatetimeStringModel.nullable(),
  shipments_count: z.number().nullable(),
  shipping_address: ChargeAddressModel,
  shipping_lines: z.array(ShippingLineModel).optional(),
  shopify_order_id: z.string().nullable(),
  status: ChargeStatusModel,
  subtotal_price: z.string().nullable(),
  tags: z.array(z.string()),
  tax_lines: z.number(),
  total_discounts: z.number(),
  total_duties: z.string().nullable(),
  total_line_items_price: z.number(),
  total_price: z.string(),
  total_refunds: z.string().nullable(),
  total_tax: z.number(),
  total_weight: z.number(),
  transaction_id: z.union([z.literal('Shopify Checkout'), z.string()]),
  type: z.union([z.literal('CHECKOUT'), z.literal('RECURRING')]),
});
