import { Stack } from '@customer-portal/affinity-components';
import { Continue } from './components/continue';
import { ExperiencesUpsellProduct } from './features/experiences/UpsellProduct';
import { UpsellProductAutoRedirect } from './features/experiences/UpsellProductAutoRedirect';

export const ThemeEngine = () => {
  const componentsList = window.Schema?.order || [];

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '24px 0' }}>
      <Stack space="xlarge">
        {componentsList.map((name, index) => {
          switch (name) {
            case 'recharge-experience-upsell':
              return (
                <Stack space="xxxlarge" key={index}>
                  <ExperiencesUpsellProduct key={index} />
                  <UpsellProductAutoRedirect />
                  <Continue />
                </Stack>
              );

            default:
              return null;
          }
        })}
      </Stack>
    </div>
  );
};
