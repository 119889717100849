import { ActionLink, Card, Heading, Layout, Stack, Text } from '@customer-portal/affinity-components';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { Sentry } from '../config/sentry';

export const Splashscreen = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Card tone="neutral">
        <Stack space="xlarge">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none">
            <g stroke="var(--recharge-color-brand)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
              <path d="M54.4 20.633v-15a3.75 3.75 0 0 0-3.75-3.75h-45a3.75 3.75 0 0 0-3.75 3.75v41.25a3.75 3.75 0 0 0 3.75 3.75h15M1.9 13.133h52.5" />
              <path d="M43.145 58.133c8.284 0 15-6.716 15-15 0-8.285-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15ZM32.54 53.737l21.21-21.21" />
            </g>
          </svg>

          <Stack space="medium">
            <Heading as="h1">{t('app_error')}</Heading>

            <Text variant="p3">{t('app_error_description')}</Text>

            <Text variant="p3">{t('app_error_details')}</Text>

            <ActionLink onPress={() => window.history.back()}>{t('app_error_go_back')}</ActionLink>

            {window.ReCharge?.store && (
              <ActionLink onPress={() => (window.location.href = `https://${window.ReCharge.store}`)}>
                {t('app_error_back_to_store')}
              </ActionLink>
            )}
          </Stack>
        </Stack>
      </Card>
    </Layout>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  return <Splashscreen />;
};
