import { ReactNode, useContext, useMemo, useState } from 'react';

import { createContext } from 'react';

interface InlineAlertContext {
  message?: ReactNode;
  clear: () => void;
  setMessage: (message: ReactNode) => void;
}

export const InlineAlertContext = createContext<InlineAlertContext>({
  message: undefined,
  clear: () => null,
  setMessage: () => null,
});

InlineAlertContext.displayName = 'InlineAlertContext';

export function InlineAlertProvider({ children, defaultMessage }: { children: ReactNode; defaultMessage?: ReactNode }) {
  const [message, setMessage] = useState<ReactNode>(defaultMessage);
  const value = useMemo(() => ({ message, setMessage, clear: () => setMessage(undefined) }), [message, setMessage]);
  return <InlineAlertContext.Provider value={value}>{children}</InlineAlertContext.Provider>;
}

export function useInlineAlert() {
  return useContext(InlineAlertContext);
}
