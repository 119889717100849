import { Theme, Toast, ToastProvider } from '@customer-portal/affinity-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ViewsProvider } from '../../theme/src/components/ViewsProvider';
import { Splashscreen } from '../../theme/src/components/ErrorBoundary';
import { setupI18n } from '../../theme/src/config/i18n';
import { queryClient } from '../../theme/src/config/queryClient';
import { router } from './config/router';
import { ErrorBoundary, setupSentry } from '../../theme/src/config/sentry';
import { InlineAlertProvider } from '../../theme/src/providers/InlineAlertProvider';
import { RechargeProvider } from '../../theme/src/providers/RechargeProvider';

setupSentry();

setupI18n();

function render() {
  ReactDOM.createRoot(document.getElementById('recharge-customer-portal') as HTMLElement).render(
    <ErrorBoundary fallback={Splashscreen} showDialog>
      <ViewsProvider>
        <QueryClientProvider client={queryClient}>
          <RechargeProvider>
            <Theme>
              <ToastProvider>
                <InlineAlertProvider>
                  <RouterProvider router={router} />
                  <Toast />
                </InlineAlertProvider>
              </ToastProvider>
            </Theme>
            <ReactQueryDevtools />
          </RechargeProvider>
        </QueryClientProvider>
      </ViewsProvider>
    </ErrorBoundary>
  );
}

render();
