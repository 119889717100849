import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type {
  Modification,
  ModificationAttribute,
  OrdersThemeEngineResponse,
  ThemeEngineOrder,
} from '~/types/api/response/orders';

import { get } from '~/common/rest';
import {
  generateNextPageFilters,
  generatePreviousPageFilters,
  hasNextPageMetaless,
  hasPreviousPageMetaless,
  ensureArray,
} from '../utils';

export async function getOrders(context: ApiContext, filters: Payloads.GetOrdersPayload = {}) {
  const schema = { orders: filters };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<OrdersThemeEngineResponse>(endpointUrl);

  return {
    next: hasNextPageMetaless(filters, response.orders) ? generateNextPageFilters(filters) : null,
    previous: hasPreviousPageMetaless(filters) ? generatePreviousPageFilters(filters) : null,
    orders: ensureArray(response.orders).map(order => ({
      ...order,
      simple_modifications: simplifyModifications(order),
    })),
  };
}

export async function getOrder(context: ApiContext, filters: Payloads.GetOrderPayload) {
  const { orders } = await getOrders(context, filters);

  if (orders.length === 0) {
    return null;
  }
  return orders[0];
}

function getLineItemModificationAttribute(line_item_mod: Modification, attribute_name: ModificationAttribute) {
  return line_item_mod.modifications.find(modified_attr => modified_attr.attribute == attribute_name);
}
function simplifyModifications(order: ThemeEngineOrder) {
  const modifications = order.include?.order_modifications ?? [];

  return modifications.flatMap(mod => {
    const lines = mod.modifications.line_items;
    return lines.flatMap(x => {
      if (x.modification_type === 'update') {
        const lineItem = order?.line_items.find(x => x.subscription_id === x.subscription_id);

        // filter out member discount mods, we use this to calculate removed price/quantity only
        const modificationsNoMembershipDiscounts = x.modifications.filter(
          mod => mod.reason !== 'Membership pricing discount'
        );

        if (lineItem == null || modificationsNoMembershipDiscounts.length === 0) {
          return [];
        }

        return {
          subscription_id: x.subscription_id,
          product_title: lineItem.product_title,
          variant_title: lineItem.variant_title,
          removed_quantity:
            Number(getLineItemModificationAttribute(x, 'quantity')?.previous_value ?? 0) -
            Number(getLineItemModificationAttribute(x, 'quantity')?.value ?? 0),
          removed_price: Number(lineItem.price),
          images: lineItem.images,
        };
      }

      if (x.modification_type === 'delete') {
        return {
          subscription_id: x.subscription_id,
          product_title: getLineItemModificationAttribute(x, 'product_title')?.previous_value,
          variant_title: getLineItemModificationAttribute(x, 'variant_title')?.previous_value,
          removed_quantity: Number(getLineItemModificationAttribute(x, 'quantity')?.previous_value ?? 0),
          removed_price: Number(getLineItemModificationAttribute(x, 'price')?.previous_value ?? 0),
          image_url: getLineItemModificationAttribute(x, 'image')?.previous_value,
        };
      }
      return [];
    });
  });
}
