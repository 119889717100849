/**
 * Verifies that the passed value is a plain object.
 * This returns false when values inherit the Object prototype, but are not
 * literal objects.
 * @param value The value to be checked if it's a plain object or not.
 * @returns A boolean denoting whether the passed value is a plain object.
 */
export function isPlainObject(value: any): boolean {
  if (typeof value !== 'object' || value === null) return false;

  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(value) === proto;
}
