import type { ApiContext } from '~/types/strategies';
import type { StoreDataThemeEngineResponse } from '~/types/api/response/store';

import { get } from '~/common/rest';

export async function getStoreData(context: ApiContext) {
  const schema = {
    store: {},
    settings: {},
    shipping_countries: [],
    retention_strategies: [],
  };

  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = get<StoreDataThemeEngineResponse>(endpointUrl);
  return response;
}
