import { UnionToIntersection } from '~/types/common';
import { ApiContext, ApiStrategy } from '~/types/strategies';

export type Analytics = { analytics?: Record<string, string> };

export type AnalyticsDecorator<T> = T extends (payload: infer P) => infer R ? (payload?: P & Analytics) => R : T;

export function analyticsDecorator(context: ApiContext, fn: ApiStrategy[keyof ApiStrategy]) {
  type FnParameters = Parameters<typeof fn>;
  type FnPayload = UnionToIntersection<NonNullable<FnParameters[1]>>;
  type FnReturnType = ReturnType<typeof fn>;

  return (payload: FnPayload & Analytics): FnReturnType => {
    const generateUrlWithParams = (
      baseUrl: string,
      token: string,
      subPath?: string,
      params?: Record<string, string>
    ) => {
      const { analytics } = payload || {};
      const paramsWithAnalytics = { ...analytics, ...params, client: 'affinity' };
      return context.urlGenerator(baseUrl, token, subPath, paramsWithAnalytics);
    };

    return fn({ ...context, urlGenerator: generateUrlWithParams }, payload);
  };
}
