import { ClaimExperiencePayload } from '@customer-portal/affinity-api';
import { useMutation } from '@tanstack/react-query';
import { useApi } from './useApi';

export type Payload = ClaimExperiencePayload;

export const useClaimExperienceMutation = () => {
  const { api } = useApi();

  return useMutation((payload: Payload) => api.claimExperience(payload));
};
