import { Children, CSSProperties, ReactNode } from 'react';
import { Box } from '../Box';
import { vars } from '../theme.css';
import { Align, alignToFlexAlign, AlignY, alignYToFlexAlign } from '../utils/align';
import * as styles from './Columns.css';

export type Columns = {
  children: ReactNode;
  align?: Align;
  alignY?: AlignY;
  space?: keyof typeof vars.size;
  wrap?: Box['flexWrap'];
  direction?: Box['flexDirection'];
};

export interface Column {
  children?: ReactNode;
  space?: Box['gap'];
  width?: Box['flex'];
}

export const Column = ({ children, width = 'auto' }: Column) => {
  return (
    <Box className={styles.Column} flex={width} overflow="hidden">
      {children}
    </Box>
  );
};

export const Columns = ({ children, direction, space = 'none', wrap = 'nowrap', align, alignY }: Columns) => {
  return (
    <Box
      alignItems={alignYToFlexAlign(alignY)}
      justifyContent={alignToFlexAlign(align)}
      display="flex"
      flexDirection={direction}
      gap={space}
      flexWrap={wrap}
      style={
        {
          '--rc-columns-total': Children.toArray(children).length,
          '--rc-columns-n': Children.toArray(children).length - 1,
          '--rc-columns-gap': vars.size[space],
        } as CSSProperties
      }
    >
      {children}
    </Box>
  );
};
