import { useContext } from 'react';
import { RechargeContext } from '../context/RechargeContext';

export type Authentication = {
  token: string | null;
  hash: string | null;
};

export function useAuthentication() {
  return useContext(RechargeContext).auth;
}
