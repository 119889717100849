import { Skeleton } from '@customer-portal/affinity-components';
import { useGetDeliverySchedule } from '../../../../../theme/src/api/useGetDeliverySchedule';
import { useGetExperiences } from '../../../../../theme/src/api/useGetExperiences';
import { useGetProducts } from '../../../../../theme/src/api/useGetProducts';
import { useGetStoreData } from '../../../../../theme/src/api/useGetStoreData';
import { isExperiencesUpsellProduct } from '../../../../../theme/src/utils/experiences';
import { OffersList } from './OffersList';

/**
 * Experiences: Upsell Product
 * Customers are surprised with multiple upsell product offer.
 * Customers can claim a offer.
 */
export const ExperiencesUpsellProduct = () => {
  const { data: store } = useGetStoreData();

  const { data: schedules } = useGetDeliverySchedule();

  const isFeatureEnabled = !!store?.settings?.customer_portal.wfs_flows_enabled;

  const { data, isLoading: isLoadingExperiences } = useGetExperiences(
    {
      use_flow: '1',
    },
    {
      enabled: isFeatureEnabled,
    }
  );

  const experience = data?.experiences.find(isExperiencesUpsellProduct);

  const ids = Object.keys(experience?.offer_data.product_variants || {}).join(',');

  const { data: products } = useGetProducts(
    { shopify_product_id: ids },
    { enabled: isFeatureEnabled && ids.length > 0 }
  );

  if (!isFeatureEnabled) {
    return null;
  }

  if (isLoadingExperiences) {
    return <Skeleton height={240} />;
  }

  if (!experience || !schedules?.length || !products?.products.length) {
    return null;
  }

  return <OffersList experience={experience} store={store} product={products.products[0]} />;
};
