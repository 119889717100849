import { Charge, DeliverySchedule, Onetime, Product } from '@customer-portal/affinity-api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SubscriptionWithProduct } from '../types/subscriptions';
import { groupBy } from '../utils/array';
import { setBundleProductDataToEachOrderSubscription } from '../utils/order';
import { useApi } from './useApi';
import { CHARGES_QUERY_KEY } from './useGetCharges';
import { SUBSCRIPTIONS_QUERY_KEY } from './useGetSubscriptions';

const QUERY_KEY = 'delivery-schedule';

/**
 * subscriptionToScheduleOrder
 * Turns a subscription into a DeliverySchedule order unit
 */
const subscriptionToScheduleOrder = (
  subscription: SubscriptionWithProduct,
  charge: Charge,
  product: Product
): DeliverySchedule['orders'][number] => ({
  charge,
  order: {}, // TODO => This may be issue for prepaids since they have orders
  is_skippable: subscription.is_skippable,
  is_prepaid: subscription.is_prepaid,
  is_skipped: false, // If it exists in the subscription, it's not skipped.
  shipment_type: 'PROJECTED_SHIPMENT', // This is the only value possible
  price: charge?.total_price || '0',
  subscription: {
    ...subscription,
    product,
    charge_interval_unit_type: subscription.order_interval_unit,
    bundle_product: subscription.bundle_product,
  },
});

/**
 * onetimeToScheduleOrder
 * Turns a Onetime into a DeliverySchedule order unit
 */
const onetimeToScheduleOrder = (onetime: Onetime, charge: Charge): DeliverySchedule['orders'][number] => ({
  charge,
  order: {},
  is_skippable: false,
  is_prepaid: false,
  is_skipped: false, // If it exists in the subscription, it's not skipped.
  shipment_type: 'PROJECTED_SHIPMENT', // This is the only value possible
  price: charge?.total_price || '0',
  subscription: {
    // Original props
    ...onetime,

    cancellation_reason_comments: null,
    cancellation_reason: null,
    cancelled_at: null,
    charge_interval_frequency: 0, // @TODO: null
    charge_interval_unit_type: 'day', // @TODO: null
    expire_after_specific_number_of_charges: null,
    has_queued_charges: 1,
    id: onetime.id,
    is_prepaid: false,
    is_skippable: false,
    is_swappable: false,
    locked_pending_charge_id: 0,
    max_retries_reached: 0,
    order_day_of_month: null,
    order_day_of_week: null,
    order_interval_frequency: '0', // @TODO: null
    order_interval_unit: 'day', // @TODO: null
    sku_override: false,
    status: 'ONETIME',

    // Optional
    charge_delay: null,
    cutoff_day_of_month_before_and_after: null,
    cutoff_day_of_week_before_and_after: null,
    email: '',
    first_charge_date: null,
    order_interval_frequency_options: [],
  },
});

/**
 * Parses a charge line items, unique by _rc_bundle property.
 */
const parseLineItems = (lineItems: Charge['line_items']) => {
  const seen = new Set();

  return lineItems.filter(item => {
    const key = item.properties.find(prop => prop.name === '_rc_bundle')?.value;

    if (key === undefined) {
      return true;
    }

    const isAdded = seen.has(key);

    if (!isAdded) {
      seen.add(key);
    }

    return !isAdded;
  });
};

const isAddon = (lineItem: Charge['line_items'][number]) =>
  lineItem.properties.find(({ name, value }) => name === 'add_on' && value.toLowerCase() === 'true');

export const useGetDeliverySchedule = () => {
  const { api } = useApi();

  const getDeliverySchedule = async (customerId: string) => {
    const schedule = await api.getDeliverySchedule({ customerId });

    if (!schedule.length) return schedule;

    // Add the bundle product data to each subscription available in the first schedule orders
    const subscriptionIds = schedule[0].orders.map(order => order.subscription.id).join(',');
    const subscriptions = await api.getSubscriptions({
      ids: subscriptionIds,
      bundle_product: {},
      bundle_selections: {},
    });

    const [nextOrder, ...futureOrders] = schedule;

    const nextOrdersWithBundleProduct = {
      ...nextOrder,
      orders: setBundleProductDataToEachOrderSubscription(schedule[0].orders, subscriptions?.subscriptions),
    };

    return [nextOrdersWithBundleProduct, ...futureOrders];
  };

  const customerId = '';

  /**
   * Constructs a delivery schedule by charges.
   */
  const query = useQuery([QUERY_KEY], async () => {
    const chargesReq = api.getCharges({ status: 'SKIPPED,QUEUED,ERROR' });
    const scheduleReq = getDeliverySchedule(customerId);

    const [schedule, chargesData] = await Promise.all([scheduleReq, chargesReq]);

    const charges = chargesData.filter(charge =>
      charge.status === 'SKIPPED' ? new Date(charge.created_at) > new Date() : true
    );

    const hasChargeErrors = Boolean(charges.find(charge => charge.status === 'ERROR'));

    if (!hasChargeErrors && schedule.length) {
      /**
       * We need order_modifications include on the charge/order to calculate membership pricing discount, among other things
       * order_modifications include is returned by default on orders/charges requested directly, but they're not included on the schedule response
       * so, we need to merge the charges from chargesReq with the schedule orders, so we have access to their order_modifications include
       * ideally, the schedule would also include order_modifications on the charge/orders, but for now this should be OK
       */

      const [...scheduleCopy] = schedule;

      scheduleCopy.forEach(schedule =>
        schedule.orders.forEach(order => {
          const charge = charges.find(charge => charge.id === order.charge.id);
          if (charge) {
            order.charge = charge;
          }
        })
      );

      return scheduleCopy;
    }

    if (!charges.length) {
      return [];
    }

    const lineItems = charges.flatMap(charge => charge.line_items);

    const subscriptionIds = lineItems
      .filter(lineItem => lineItem.type === 'SUBSCRIPTION')
      .map(lineItem => lineItem.subscription_id);

    const onetimeIds = lineItems
      .filter(lineItem => lineItem.type === 'ONETIME')
      .map(lineItem => lineItem.subscription_id);

    const subscriptionsRequest = api.getSubscriptions({
      ids: subscriptionIds.join(','),
      bundle_product: {},
      bundle_selections: {},
    });

    const onetimesRequest = api.getOnetimes({
      ids: onetimeIds.join(','),
      bundle_product: {},
      bundle_selections: {},
    });

    const [subscriptions, onetimes] = await Promise.all([subscriptionsRequest, onetimesRequest]);

    // Get products from onetimes and subscriptions
    const productIds = [...subscriptions.subscriptions, ...onetimes.onetimes].map(entity => entity.shopify_product_id);
    const { products } = await api.getProducts({ external_product_ids: productIds.join(',') });
    const productLookup = products.reduce((acc, product) => {
      if (!acc[product.external_product_id]) {
        acc[product.external_product_id] = product;
      }
      return acc;
    }, {} as Record<string, Product>);

    const fallbackSchedule: DeliverySchedule[] = Object.entries(groupBy(charges, charge => charge.scheduled_at || ''))
      .sort(([scheduledAt1], [scheduledAt2]) => (new Date(scheduledAt1) > new Date(scheduledAt2) ? 1 : -1))
      .map(([scheduledDate, charges]) => ({
        date: scheduledDate,
        orders: charges.flatMap(charge =>
          parseLineItems(charge.line_items)
            // Remove line items add-ons to avoid errors trying to format an object that's not a subscription that generates an infinity loop.
            .filter(lineItem => !isAddon(lineItem))
            .filter(lineItem => lineItem.subscription_id !== 0)
            .map(lineItem => {
              if (lineItem.type === 'ONETIME') {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const onetime = onetimes.onetimes.find(sub => sub.id === lineItem.subscription_id)!;

                return onetimeToScheduleOrder(onetime, charge);
              }

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const subscription = subscriptions.subscriptions.find(sub => sub.id === lineItem.subscription_id)!;
              const product = productLookup[String(subscription.shopify_product_id)];

              return subscriptionToScheduleOrder(subscription, charge, product);
            })
        ),
      }))
      .filter(schedule => schedule.orders.length);

    return fallbackSchedule;
  });

  return {
    ...query,
    orders: query.data,
    nextOrder: query.data && query.data[0],
    remainingOrders: query.data && query.data.slice(1),
  };
};

export const useInvalidateGetDeliverySchedule = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    queryClient.invalidateQueries([QUERY_KEY]);
    queryClient.invalidateQueries([CHARGES_QUERY_KEY]);
    // Since we're adding the bundle product data to each order subscription using subscription request
    // we have to invalidate the cache for subscriptions to ensure we always have fresh data
    queryClient.invalidateQueries([SUBSCRIPTIONS_QUERY_KEY]);
  };

  return {
    invalidate,
  };
};

export const SCHEDULE_KEYS = [QUERY_KEY, CHARGES_QUERY_KEY, SUBSCRIPTIONS_QUERY_KEY];
