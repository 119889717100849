import { useRadio, useRadioGroup } from '@react-aria/radio';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { createContext, ReactNode, useCallback, useContext, useRef } from 'react';
import { useFocusRing } from 'react-aria';
import { Box } from '../Box';
import { Inline, InlineItem } from '../Inline';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { VisuallyHidden } from '../VisuallyHidden';
import * as styles from './RadioGroup.css';

const RadioContext = createContext<RadioGroupState>({} as RadioGroupState);

export type RadioGroup = {
  value?: string;
  label: string;
  labelHidden?: boolean;
  message?: string;
  description?: string;
  direction?: 'horizontal' | 'vertical';
  children: ReactNode;
  onChange?: (val: string) => void;
  name?: string;
  defaultValue?: string;
};

export const RadioGroup = (props: RadioGroup) => {
  const { children, label, labelHidden, description, direction = 'vertical', onChange } = props;

  const state = useRadioGroupState(props);

  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  const Wrapper = direction === 'vertical' ? Stack : Inline;

  const LabelBox = (
    <Box as="span" aria-hidden="true" {...labelProps}>
      <Text variant="p3">{label}</Text>

      {description && (
        <Box>
          <Text variant="p3">{description}</Text>
        </Box>
      )}
    </Box>
  );

  useCallback(() => {
    if (state.selectedValue && onChange) {
      onChange(state.selectedValue);
    }
  }, [state.selectedValue, onChange]);

  return (
    <Box {...radioGroupProps}>
      <RadioContext.Provider value={state}>
        <Wrapper space={labelHidden ? 'none' : 'xlarge'}>
          {labelHidden && <VisuallyHidden>{LabelBox}</VisuallyHidden>}

          {!labelHidden && LabelBox}

          <Wrapper space="xlarge" wrap="nowrap">
            {children}
          </Wrapper>
        </Wrapper>
      </RadioContext.Provider>
    </Box>
  );
};

export type RadioItem = {
  tone?: 'neutral' | 'critical';
  label: ReactNode;
  message?: string;
  description?: string;
  value: string;
  disabled?: boolean;
  children?: ReactNode;
};

export const RadioItem = (props: RadioItem) => {
  const { label, disabled, message, description, children, tone = 'neutral' } = props;

  const state = useContext(RadioContext);

  const ref = useRef(null);

  const { inputProps } = useRadio({ ...props, children: label }, state, ref);

  const { isFocusVisible, focusProps } = useFocusRing();

  const { color, height, width, ...inputPartialProps } = inputProps;

  const checked = state.selectedValue === props.value;

  return (
    <Box as="label" position="relative" className={styles.root({ checked, tone })}>
      <VisuallyHidden>
        <Box as="input" {...inputPartialProps} {...focusProps} ref={ref} disabled={disabled} />
      </VisuallyHidden>

      <Inline space="medium" wrap="nowrap" container="none">
        <InlineItem shrink={0}>
          <Box position="relative" display="flex" alignItems="center" justifyContent="center" borderRadius="full">
            <Box
              borderRadius="full"
              className={styles.radio({
                checked,
                disabled,
                tone,
                focus: isFocusVisible,
              })}
            >
              <Box as="span" className={styles.bullet({ checked, disabled, tone })} />
            </Box>
          </Box>
        </InlineItem>

        <InlineItem grow={1}>
          <Box>
            <Text variant="p3">{label}</Text>

            {description && (
              <Box>
                <Text variant="p3">{description}</Text>
              </Box>
            )}

            {message && <Box>{message && <Text variant="p3">{message}</Text>}</Box>}
            {children}
          </Box>
        </InlineItem>
      </Inline>
    </Box>
  );
};
