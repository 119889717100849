import { z } from 'zod';

const PlanChannelSettingsModel = z.object({
  display: z.boolean(),
});

export const PlanTypeModel = z.union([
  z.literal('prepaid'),
  z.literal('subscription'),
  z.literal('onetime'),
  z.literal('membership_subscription'),
]);

const PlanSubscriptionPreferencesModel = z.object({
  chargeIntervalFrequency: z.number().nullable(),
  expireAfterSpecificNumberOfCharges: z.number().min(1).max(31).nullable().optional(),
  orderIntervalFrequency: z.number().min(1).max(31).nullable(),
  intervalUnit: z.union([z.literal('day'), z.literal('week'), z.literal('month')]).nullable(),
  orderDayOfMonth: z.number().nullable().optional(),
  cutoffDayOfMonth: z.number().nullable().optional(),
  orderDayOfWeek: z.number().nullable().optional(),
  cutoffDayOfWeek: z.number().nullable().optional(),
});

export const PlanModel = z.object({
  id: z.string(),
  discountAmount: z.string().nullable(),
  discountType: z.union([z.literal('percentage'), z.literal('fixed_amount')]).nullable(),
  sortOrder: z.number(),
  title: z.string(),
  type: PlanTypeModel,
  channelSettings: PlanChannelSettingsModel,
  subscriptionPreferences: PlanSubscriptionPreferencesModel,
  hasVariantRestrictions: z.boolean(),
  externalVariantIds: z.array(z.string()),
});
