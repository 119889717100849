import { z } from 'zod';

export const MembershipModel = z.object({
  purchase_item_id: z.number(),
  purchase_item_type: z.string(),
  id: z.number(),
  type: z.union([z.literal('manual'), z.literal('membership_subscription'), z.literal('physical_subscription')]),
  status: z.union([z.literal('active'), z.literal('inactive')]),
  cancelled_at: z.string().nullable(),
  expires_at: z.string().nullable(),
  product_title: z.string(),
  variant_title: z.string().nullable(),
  price: z.string(),
  quantity: z.number(),
  presentment_currency: z.string(),
});

export const MembershipConditionModel = z.object({
  id: z.number(),
  include: z.object({
    plan: z
      .object({
        type: z.string(),
      })
      .optional(),
  }),
  plan_id: z.union([z.number(), z.null()]),
  product: z.object({
    id: z.string(),
  }),
});
