import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Label } from '../Label/Label';
import * as styles from './TextField.css';

export type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> &
  Omit<Label, 'labelFor'> &
  styles.TextFieldVariants & {
    icon?: ReactNode;
    iconPosition?: 'start' | 'end';
    disabled?: boolean;
  };

const TextFieldRaw = (
  {
    id,
    name,
    label,
    labelHidden,
    description,
    message,
    type,
    disabled = false,
    placeholder,
    icon,
    required,
    pattern,
    title,
    iconPosition = 'start',
    value,
    inputMode,
    tone = 'neutral',
    autoFocus = false,
    onFocus,
    onBlur,
    onChange,
    size,
    defaultValue,
  }: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <Label
      label={label}
      labelFor={id}
      labelHidden={labelHidden}
      description={description}
      message={message}
      tone={tone}
    >
      <Box
        display="flex"
        alignItems="center"
        gap="medium"
        disabled={disabled}
        className={`recharge-textfield ${styles.root({ disabled, tone, size })}`}
      >
        {icon && iconPosition === 'start' && <Icon size="xxlarge">{icon}</Icon>}
        <Box
          as="input"
          id={id}
          ref={ref}
          name={name}
          maxWidth="full"
          type={type}
          value={value}
          required={required}
          autoFocus={autoFocus}
          inputMode={inputMode}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          className={styles.input({ disabled, tone })}
          disabled={disabled}
          placeholder={placeholder}
          pattern={pattern}
          title={title}
          defaultValue={defaultValue}
        />
        {icon && iconPosition === 'end' && <Icon size="xxlarge">{icon}</Icon>}
      </Box>
    </Label>
  );
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(TextFieldRaw);

TextField.displayName = 'TextField';
