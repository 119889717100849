import { GetSubscriptionsPayload } from '@customer-portal/affinity-api';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from './useApi';

export const SUBSCRIPTIONS_QUERY_KEY = 'subscriptions';

export type Params = {
  filters?: GetSubscriptionsPayload;
  options?: {
    enabled: boolean;
  };
};

export function useGetSubscriptions({ filters = {}, options = { enabled: true } }: Params = {}) {
  const { api } = useApi();
  return useQuery([SUBSCRIPTIONS_QUERY_KEY, filters], () => api.getSubscriptions(filters), options);
}

export const useInvalidateGetSubscriptions = () => {
  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries([SUBSCRIPTIONS_QUERY_KEY]);

  return {
    invalidate,
  };
};

export const useInfinitySubscriptions = ({ filters = {}, options = { enabled: true } }: Params = {}) => {
  const { api } = useApi();

  return useInfiniteQuery(
    [SUBSCRIPTIONS_QUERY_KEY, filters],
    ({ pageParam }) =>
      api.getSubscriptions({
        ...filters,
        page: pageParam,
      }),
    {
      getNextPageParam: lastPage => lastPage.next?.page,
      enabled: options.enabled,
    }
  );
};
