import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { AddressThemeEngineResponse } from '~/types/api/response/addresses';
import type { ThemeEngineUpdateAddressPayload } from '~/types/api/request/addresses';

import { post } from '~/common/rest';

export async function updateAddress(context: ApiContext, { id, payload }: Payloads.UpdateAddressPayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/addresses/${id}`);

  const response = await post<AddressThemeEngineResponse, ThemeEngineUpdateAddressPayload>(endpointUrl, payload);
  return response.address;
}
