import type { RequestWrapper, RequestWrapperResponse } from '~/types/http';

import { isPlainObject } from '~/common/object';
import { makeJsonRequest } from '~/common/requests';
import { handleJsonResponse } from '~/common/responses';

type MakeRequestFn = RequestWrapper;
type HandleResponseFn = <T extends object>(response: RequestWrapperResponse) => Promise<T>;

type MethodOptions = {
  makeRequest: MakeRequestFn;
  handleResponse: HandleResponseFn;
};

function stringifyPayload(payload?: object): string | undefined {
  if (!isPlainObject(payload)) return undefined;
  return JSON.stringify(payload);
}

function normalizeOptions(options: Partial<MethodOptions>): MethodOptions {
  return {
    makeRequest: options.makeRequest || makeJsonRequest,
    handleResponse: options.handleResponse || handleJsonResponse,
  };
}

export async function get<ResponseBody extends object>(url: string, _options: Partial<MethodOptions> = {}) {
  const options = normalizeOptions(_options);

  const response = await options.makeRequest(url, { method: 'GET' });
  return options.handleResponse<ResponseBody>(response);
}

export async function post<ResponseBody extends object, RequestPayload extends object = object>(
  url: string,
  body?: RequestPayload,
  _options: Partial<MethodOptions> = {}
) {
  const options = normalizeOptions(_options);

  const requestBody = body || {};
  const response = await options.makeRequest(url, { method: 'POST', body: stringifyPayload(requestBody) });
  return options.handleResponse<ResponseBody>(response);
}

export async function put(url: string, body?: Record<string, unknown>, _options: Partial<MethodOptions> = {}) {
  const options = normalizeOptions(_options);

  const response = await options.makeRequest(url, { method: 'PUT', body: stringifyPayload(body) });
  return options.handleResponse(response);
}

export async function patch(url: string, body?: Record<string, unknown>, _options: Partial<MethodOptions> = {}) {
  const options = normalizeOptions(_options);

  const response = await options.makeRequest(url, { method: 'PATCH', body: stringifyPayload(body) });
  return options.handleResponse(response);
}

export async function remove(url: string, _options: Partial<MethodOptions> = {}) {
  const options = normalizeOptions(_options);

  const response = await options.makeRequest(url, { method: 'DELETE' });
  return options.handleResponse(response);
}
