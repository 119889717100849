import { createBrowserRouter, ScrollRestoration } from 'react-router-dom';
import { ErrorBoundary } from '../../../theme/src/components/ErrorBoundary';
import { getUrlData } from '../../../theme/src/utils/url';
import { ThemeEngine } from '../ThemeEngine';

export const router = createBrowserRouter(
  [
    {
      path: '*',
      element: (
        <>
          <ThemeEngine />
          <ScrollRestoration />
        </>
      ),
      errorElement: <ErrorBoundary />,
    },
  ]
);
