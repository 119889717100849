import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineAddress } from '~/types/api/response/addresses';

import { getAddresses } from './get';
import { mergeAddresses } from './merge';

export async function moveOrders(context: ApiContext, { id }: Payloads.MoveOrdersPayload) {
  const addresses = await getAddresses(context);
  // Get all the addresses different to the target address
  const modifiableAddresses = addresses
    .filter(address => address.id.toString() !== id)
    .map(address => address.id.toString());

  if (modifiableAddresses.length === 0) {
    const targetAddress = addresses.find(address => address.id.toString() === id)!;
    return { success: [], failure: [], address: targetAddress };
  }

  return mergeAddressesChunked(context, id, modifiableAddresses);
}

async function mergeAddressesChunked(context: ApiContext, targetAddressId: string, sourceAddressIds: string[]) {
  const ITEMS_PER_CHUNK = 10;

  const chunksAmount = Math.ceil(sourceAddressIds.length / ITEMS_PER_CHUNK);
  const mergeChunks = Array.from({ length: chunksAmount }).map((_, index) =>
    sourceAddressIds.slice(index * ITEMS_PER_CHUNK, (index + 1) * ITEMS_PER_CHUNK)
  );

  let addressResult: ThemeEngineAddress | null = null;
  const failedMerges: string[] = [];

  for (const sourceAddresses of mergeChunks) {
    try {
      addressResult = await mergeAddresses(context, { targetAddress: targetAddressId, sourceAddresses });
    } catch {
      failedMerges.push(...sourceAddresses);
    }
  }

  return {
    success: sourceAddressIds.filter(addressId => !failedMerges.includes(addressId)),
    failure: failedMerges,
    address: addressResult,
  };
}
