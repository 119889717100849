import type * as Payloads from '~/types/requests';
import type { SubscriptionsThemeEngineResponse, ThemeEngineSubscription } from '~/types/api/response/subscriptions';
import type { DelayOrderPayload } from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

import { DateTime, Settings } from 'luxon';
import { post } from '~/common/rest';

import { getStoreData } from '../store';

async function bulkUpdateNextCharge(
  context: ApiContext,
  { addressId, subscriptionIds, newDate }: { addressId: number; subscriptionIds: number[]; newDate: string }
) {
  const url = context.urlGenerator(context.baseUrl, context.token, `/addresses/${addressId}/subscriptions-bulk-update`);
  const payload = subscriptionIds.map(subscriptionId => ({ id: subscriptionId, next_charge_scheduled_at: newDate }));
  const response = await post<SubscriptionsThemeEngineResponse>(url, { subscriptions: payload });
  return response.subscriptions;
}

function updateOnetimeChargeDate(
  context: ApiContext,
  { onetimeId, nextChargeDate }: { onetimeId: number; nextChargeDate: string }
): Promise<{ onetime: ThemeEngineSubscription }> {
  const url = context.urlGenerator(context.baseUrl, context.token, `/onetimes/${onetimeId}/set_next_charge_date`);
  const payload = { next_charge_scheduled_at: nextChargeDate };
  return post(url, payload);
}

async function getAbsoluteTime(context: ApiContext, payload: DelayOrderPayload) {
  // Get store settings — we need the IANA Timezone
  const { store } = await getStoreData(context);
  const ianaTimezone = store.iana_timezone || 'America/New_York'; // Uses New York as the default timezone

  const { frequency, unit, date } = payload;

  let zonedCurrentDate: DateTime;
  try {
    // I know, I know. I hate this aspect of the Luxon library.
    // We can't modify settings for single call, but we need to modify the
    // settings in a kind-of Singleton they have. To prevent this modification
    // from bleeding, we reset that in the "finally" statement.
    Settings.throwOnInvalid = true;
    zonedCurrentDate = DateTime.fromISO(date).setZone(ianaTimezone, { keepLocalTime: true });
  } catch {
    zonedCurrentDate = DateTime.fromISO(date).setZone('America/New_York', { keepLocalTime: true });
  } finally {
    Settings.throwOnInvalid = false;
  }

  const zonedNewDate = zonedCurrentDate.plus({ [unit]: frequency });
  return zonedNewDate.toFormat('yyyy-MM-dd');
}

/**
 * It updates the next charge dates for all the subscriptions/onetime that are
 * expected to be processed in an order.
 */
export async function delayOrder(context: ApiContext, payload: Payloads.DelayOrderPayload) {
  const onetimeIds = payload.onetimeIds || [];
  const subscriptionIds = payload.subscriptionIds || [];
  const addressId = payload.addressId;

  const newDate = await getAbsoluteTime(context, payload);
  const subscriptions = await bulkUpdateNextCharge(context, { addressId, subscriptionIds, newDate });

  const successfulDelays: ThemeEngineSubscription[] = [];
  for (const subscription of subscriptions) {
    successfulDelays.push(subscription);
  }

  if (subscriptionIds.length > 0 && successfulDelays.length === 0) {
    return { success: [], failure: [...subscriptionIds, ...onetimeIds], subscriptions: [] };
  }

  const successfulOnetimeResults = [];
  const failedOnetimeResults = [];
  for (const onetimeId of onetimeIds) {
    try {
      const result = await updateOnetimeChargeDate(context, { onetimeId, nextChargeDate: newDate });
      successfulOnetimeResults.push(result.onetime);
    } catch (error) {
      failedOnetimeResults.push(onetimeId);
    }
  }

  // Fill bulk operation metadata
  const successfulSubscriptions = [...successfulDelays, ...successfulOnetimeResults];
  const success = successfulSubscriptions.map(subscription => subscription.id);
  const failure = [...subscriptionIds.filter(id => !success.includes(id)), ...failedOnetimeResults];

  return {
    success,
    failure,
    subscriptions: successfulSubscriptions,
  };
}
