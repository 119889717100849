import { ReactNode } from 'react';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { VisuallyHidden } from '../VisuallyHidden';
import * as styles from './Label.css';

export type Label = styles.LabelVariants & {
  label: string;
  labelFor?: string;
  labelHidden?: boolean;
  weight?: 'normal' | 'bold';
  message?: ReactNode;
  description?: string;
  children?: ReactNode;
};

const Description = ({ children }: { children: ReactNode }) => {
  return <Text variant="p2">{children}</Text>;
};

const WithLabel = ({
  children,
  message,
  description,
  label,
}: {
  children?: ReactNode;
  message?: ReactNode;
  description?: string;
  label: ReactNode;
}) => {
  return (
    <Stack space="medium">
      <Stack space="none">
        {label}
        {description && <Description>{description}</Description>}
      </Stack>
      {children && (
        <Stack space="medium">
          {children}
          {message}
        </Stack>
      )}
    </Stack>
  );
};

const WithoutLabel = ({
  children,
  message,
  description,
  hiddenLabel,
}: {
  children?: ReactNode;
  hiddenLabel: ReactNode;
  message?: ReactNode;
  description?: string;
}) => {
  return (
    <Stack space="none">
      <VisuallyHidden>{hiddenLabel}</VisuallyHidden>
      <Stack space="medium">
        {description && <Description>{description}</Description>}
        {children}
        {message}
      </Stack>
    </Stack>
  );
};

export const Label = ({
  label,
  labelFor,
  labelHidden = false,
  weight = 'normal',
  message,
  description,
  tone = 'neutral',
  variant = 'primary',
  children,
}: Label) => {
  const Title = () =>
    weight === 'normal' ? (
      <Text variant="p3" as="span">
        {label}
      </Text>
    ) : (
      <Heading variant="h4" as="span">
        {label}
      </Heading>
    );

  const LabelBox = () => {
    return (
      <Box as="label" htmlFor={labelFor}>
        {variant === 'primary' && <Title />}

        {variant === 'secondary' && (
          <Box className={styles.root({ tone })}>
            <Title />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Stack space="medium">
      {!labelHidden ? (
        <WithLabel
          label={<LabelBox />}
          message={
            message ? (
              <Box className={styles.root({ tone })}>
                <Text variant="p2">{message}</Text>
              </Box>
            ) : null
          }
          description={description}
        >
          {children}
        </WithLabel>
      ) : (
        <WithoutLabel
          hiddenLabel={<LabelBox />}
          message={
            message && (
              <Box className={styles.root({ tone })}>
                <Text variant="p2">{message}</Text>
              </Box>
            )
          }
          description={description}
        >
          {children}
        </WithoutLabel>
      )}
    </Stack>
  );
};
