import { useEffect } from 'react';
import { getUrlData } from '../utils/url';
import { Authentication } from './useAuthentication';

function getToken() {
  const queryParams = new URLSearchParams(document.location.search);
  return queryParams.get('token');
}

function getCustomerHash() {
  let rechargeCustomerHash = window.ReCharge?.customer?.hash;
  if (!rechargeCustomerHash) {
    rechargeCustomerHash = getUrlData().customerHash || '';
  }

  return rechargeCustomerHash;
}

function setupDevCredentials() {
  const url = new URL(document.location.toString());
  const queryParams = new URLSearchParams(document.location.search);
  const devToken = queryParams.get('cp_token');
  const devHash = queryParams.get('cp_hash');

  if (devToken && devHash) {
    localStorage.setItem('cp_token', devToken);
    localStorage.setItem('cp_hash', devHash);
    url.searchParams.delete('cp_token');
    url.searchParams.delete('cp_hash');
    window.history.pushState({}, '', url);
    window.location.reload();
  }
}

function getAuthentication() {
  const token = getToken();
  const hash = getCustomerHash();

  if (token && hash) {
    return { token, hash };
  }
  return null;
}

function saveAuthentication(authentication: ReturnType<typeof getAuthentication>) {
  if (!authentication) return;

  localStorage.setItem('cp_token', authentication.token);
  localStorage.setItem('cp_hash', authentication.hash);
}

/**
 * Append the token query parameter to the URL.
 * Reads the cp_token entry from the local storage. We need this for any full
 * page reload without token.
 */
function appendTokenQueryParam() {
  const token = getToken();
  const storedToken = localStorage.getItem('cp_token');
  if (token || !storedToken) return;

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('token', storedToken);
  window.location.search = urlParams.toString();
}

export const useToken = (): Authentication => {
  useEffect(() => {
    setupDevCredentials();
  }, []);

  const auth = getAuthentication();
  saveAuthentication(auth);

  appendTokenQueryParam();

  return {
    token: auth?.token || localStorage.getItem('cp_token'),
    hash: auth?.hash || localStorage.getItem('cp_hash'),
  };
};
