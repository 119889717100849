import type { ApiContext } from '~/types/strategies';
import type { PlansResponse } from '~/types/responses';
import type { ThemeEnginePlan } from '~/types/api/response/plans';
import type { DeliveryScheduleThemeEngineResponse } from '~/types/api/response/customers';

import { get } from '~/common/rest';
import { mapPlan, mapSubscriptionProductToPlans, normalizeProduct } from '../utils';
import { SettingsThemeEngineResponse } from '~/types/api/response/store';

type DeliveryScheduleResponse = DeliveryScheduleThemeEngineResponse & SettingsThemeEngineResponse;

export async function getDeliverySchedule(context: ApiContext) {
  const schema = { settings: {}, schedule: [] };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<DeliveryScheduleResponse>(endpointUrl);

  const productIds = new Set();
  for (const delivery of response.schedule) {
    for (const order of delivery.orders) {
      if (order.subscription.product) {
        productIds.add(order.subscription.product.shopify_product_id);
      }
    }
  }

  const plansSchema = { plans: { external_product_ids: Array.from(productIds).join(',') } };
  const plansParams = { schema: JSON.stringify(plansSchema) };
  const plansEndpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', plansParams);

  const plansByProduct: Record<string, ThemeEnginePlan[]> = {};
  const plansResponse = await get<PlansResponse>(plansEndpointUrl);
  for (const plan of plansResponse.plans) {
    if (!plansByProduct[plan.external_product_id.ecommerce]) {
      plansByProduct[plan.external_product_id.ecommerce] = [];
    }
    plansByProduct[plan.external_product_id.ecommerce].push(plan);
  }

  const planMappingFn = response.settings.customer_portal.affinity_plans ? mapPlan : mapSubscriptionProductToPlans;
  return response.schedule.map(delivery => ({
    ...delivery,
    orders: delivery.orders.map(order => ({
      ...order,
      subscription: {
        ...order.subscription,
        product: order.subscription.product
          ? normalizeProduct(
              {
                ...order.subscription.product,
                plans: plansByProduct[order.subscription.product.shopify_product_id],
              },
              planMappingFn
            )
          : undefined,
      },
    })),
  }));
}
