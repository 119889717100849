import { Product } from '@customer-portal/affinity-api';
import { Box, Heading, Image, Inline, Stack, Text } from '@customer-portal/affinity-components';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../theme/src/utils/currency';
import { calculateDiscount } from '../../../../../theme/src/utils/money';

interface BasePropsWithPrices {
  selectedVariant: Product['variants'][number];
  currency: string;
}

interface BasePropsWithoutPrices {
  selectedVariant?: never;
  currency?: string;
}

type UpsellProductCardProps = (BasePropsWithPrices | BasePropsWithoutPrices) & {
  image?: string;
  selectedProduct?: Product;
  discountPercent?: string;
};

export const UpsellProductCard = ({
  image,
  selectedProduct,
  selectedVariant,
  currency,
  discountPercent,
}: UpsellProductCardProps) => {
  const { t } = useTranslation();

  return (
    <Inline space="large" alignY="center">
      {image && (
        <Inline space="medium" align="center" alignY="center">
          <Image size="medium" src={image} alt={selectedProduct?.title} />
        </Inline>
      )}

      <Stack space="none">
        {selectedProduct && <Heading variant="h2">{selectedProduct.title}</Heading>}

        {selectedVariant && (
          <Stack space="none">
            <Text variant="p3" tone="secondary">
              {selectedVariant.title}
            </Text>

            <Text variant="p3" tone="secondary">
              <Box as="s">{formatCurrency(Number(selectedVariant.price), currency)}</Box>
            </Text>

            <Text variant="p2" tone="positive">
              {formatCurrency(
                calculateDiscount(Number(selectedVariant.price), Number(discountPercent) || 0, 'percentage'),
                currency
              )}{' '}
              ({t('discount_percent_callout', { discount: `${discountPercent}%` }).toUpperCase()})
            </Text>
          </Stack>
        )}
      </Stack>
    </Inline>
  );
};
