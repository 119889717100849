export const portalPermissionControls = () => {
  const customerPortalSettings = window.ReCharge?.settings.customer_portal;

  return {
    can_use_plans: customerPortalSettings?.can_use_plans,
    allow_customer_adjust_frequency_rule: customerPortalSettings?.subscription.edit_order_frequency,
    allow_customers_to_add_products: customerPortalSettings?.subscription.add_product,
    allow_customers_to_cancel_after: customerPortalSettings?.subscription.cancellation_minimum_order_count,
    allow_customers_to_change_quantity: customerPortalSettings?.subscription.change_quantity,
    allow_customers_to_change_variants: customerPortalSettings?.subscription.change_variant,
    allow_customers_to_edit_address: customerPortalSettings?.edit_shipping_address,
    allow_customers_to_edit_upcoming_date: customerPortalSettings?.subscription.edit_scheduled_date,
    allow_customers_to_input_discount_code: customerPortalSettings?.discount_input,
    allow_customers_to_reactivate_subscription: customerPortalSettings?.subscription.reactivate_subscription,
    allow_customers_to_skip_delivery: customerPortalSettings?.subscription.skip_scheduled_order,
    allow_customers_to_skip_gift: customerPortalSettings?.gift_skipped_shipment_enabled,
    allow_customers_to_skip_prepaid:
      customerPortalSettings?.subscription.skip_scheduled_order &&
      customerPortalSettings?.subscription.skip_prepaid_order,
    allow_customers_to_swap_products: customerPortalSettings?.subscription.change_product,
    allow_customers_to_view_delivery_schedule: customerPortalSettings?.view_order_schedule,
    allow_extensions: customerPortalSettings?.can_use_affinity_extensions,
    allow_to_cancel_subscription: customerPortalSettings?.subscription.cancel_subscription,
    allow_to_cancel_memberships: customerPortalSettings?.membership?.allow_membership_cancellation_in_customer_portal,
    cancellation_email_contact: customerPortalSettings?.subscription.cancellation_email_contact,
    cancellation_reason_optional: customerPortalSettings?.subscription.cancellation_reason_optional,
    change_frequency_recalculate_next_charge:
      customerPortalSettings?.subscription.change_frequency_recalculate_next_charge,
    force_customer_portal_accounts: customerPortalSettings?.enable_membership_programs,
    inventory_behaviour: customerPortalSettings?.inventory_behaviour,
    show_credits: customerPortalSettings?.show_credits,
    subscribe_and_save_discount_enabled: customerPortalSettings?.onetime.subscribe_and_save_discount_enabled,
    memberships_is_enabled: customerPortalSettings?.enable_membership_programs,
    subscription_cancelation_workflow_enabled: customerPortalSettings?.wfs_active_churn,
    affinity_sidebar_position: customerPortalSettings?.affinity_sidebar_position,
    prevent_payment_method_creation: customerPortalSettings?.prevent_payment_method_creation,
    prevent_address_creation: customerPortalSettings?.prevent_address_creation,
    gifting_is_enabled: customerPortalSettings?.gifting_is_enabled, // true, // add this as a new task
  };
};

export type PortalPermissions = ReturnType<typeof portalPermissionControls>;
