import { z } from 'zod';

const TextModel = {
  type: z.string(),
  value: z.string(),
};

export const ExperienceFreeGiftModel = z.object({
  offer_type: z.union([
    z.literal('immediate_flow_offer'),
    z.literal('free_gift'),
    z.literal('immediate_flow_discount_add_item'),
  ]),
  offer_data: z.object({
    variation_id: z.string(),
    claim_id: z.number().nullable(),
    product_variants: z.record(
      z.string(),
      z.object({
        variant_ids: z.array(z.number()),
      })
    ),
    type: z.string(),
  }),
  metadata: z.object({
    title: z.object(TextModel).optional(),
    body_text: z.object(TextModel).optional(),
    body_html: z.object(TextModel).optional(),
    image: z.object(TextModel).optional(),
    percent_value: z.string().optional(),
    class: z.string().optional(),
  }),
});

export const ExperienceDiscountAddItemModel = z.object({
  offer_type: z.literal('immediate_flow_discount_add_item'),
  offer_data: z.object({
    claim_id: z.number().nullable(),
    product_variants: z.record(
      z.string(),
      z.object({
        variant_ids: z.array(z.number()),
      })
    ),
    variant_ids: z.array(z.number()),
  }),
  metadata: z.object({
    title: z.object(TextModel).optional(),
    body_text: z.object(TextModel).optional(),
    body_html: z.object(TextModel).optional(),
    image: z.object(TextModel).optional(),
    percent_value: z.string(),
    class: z.string().optional(),
    price_value: z.literal('None'),
    type: z.literal('discount_add_item'),
  }),
});

export const ExperienceModel = z.union([ExperienceFreeGiftModel, ExperienceDiscountAddItemModel]);

export const ExperiencesModel = z.object({
  experiences: z.union([z.array(ExperienceModel), z.record(z.string(), z.never())]),
});
