import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { ThemeEngineOnetime } from '~/types/api/response/onetimes';

import { post } from '~/common/rest';

export async function skipGift(
  context: ApiContext,
  { purchase_item_ids, recipient_address }: Payloads.GiftSkipChargePayload
): Promise<ThemeEngineOnetime[]> {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/charges/skip_gift`);

  const payload = { purchase_item_ids: purchase_item_ids.map(id => Number(id)), recipient_address };
  const response = await post<{ onetimes: ThemeEngineOnetime[] }>(endpointUrl, payload);
  return response.onetimes;
}
