import { nanoid } from 'nanoid';
import { createContext, ReactNode, useMemo, useState } from 'react';
import { Alert } from '../Alert/Alert';

export type ToastPrivateInterface = Omit<Alert, 'emphasis'> & {
  delay?: number;
  transient?: boolean;
  loading?: boolean;
  id: string;
};

export type ToastInterface = Omit<ToastPrivateInterface, 'id' | 'onClose' | 'closeLabel'>;

export interface ToastContextInterface {
  value: ToastPrivateInterface[];
  dispatch: (toast: ToastInterface) => ToastPrivateInterface;
  remove: (id: ToastPrivateInterface['id']) => void;
}

const ToastContext = createContext({} as ToastContextInterface);

ToastContext.displayName = 'ToastContext';

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<ToastPrivateInterface[]>([]);

  const dispatch = (val: ToastInterface) => {
    const newValue = { ...val, id: nanoid() };

    setValue(state => [...state, newValue]);

    return newValue;
  };

  const remove = (id: ToastPrivateInterface['id']) => {
    setValue(state => state.filter(toast => toast.id !== id));
  };

  const contextValue = useMemo(() => ({ value, dispatch, remove }), [value, dispatch, remove]);

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};

export default ToastContext;
