import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';
import type { PaymentMethodsThemeEngineResponse } from '~/types/api/response/addresses';

import { ensureArray } from '../utils';
import { get, post } from '~/common/rest';

export async function getPaymentMethods(context: ApiContext, filters: Payloads.GetPaymentMethodsPayload = {}) {
  const schema = { payment_methods: filters };
  const params = { schema: JSON.stringify(schema) };
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/request_objects', params);

  const response = await get<PaymentMethodsThemeEngineResponse>(endpointUrl);
  return ensureArray(response.payment_methods);
}

export async function updateShopifyPaymentMethod(
  context: ApiContext,
  { id }: Payloads.UpdateShopifyPaymentMethodPayload
) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, '/notifications');
  const payload = { template_type: 'shopify_update_payment_information', type: 'email', address_id: Number(id) };

  await post(endpointUrl, payload);
}
