import { Box } from '../Box';
import * as styles from './Image.css';

export type Image = styles.ImageVariants & {
  src?: string;
  alt?: string;
  disabled?: boolean;
};

export const Image = ({ src = '', alt = '', size = 'xsmall', disabled = false }: Image) => {
  return (
    <Box
      className={`recharge-image ${styles.root({ size, background: Boolean(src) })}`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      opacity={disabled ? 0.5 : 1}
    >
      <img
        className={styles.image}
        src={src}
        alt={alt}
        loading="lazy"
        onError={e => (e.currentTarget.style.display = 'none')}
      />
    </Box>
  );
};
