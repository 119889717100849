import { ExperienceDiscountAddItem, Product, StoreData } from '@customer-portal/affinity-api';
import { Box, Button, Card, Icon, Iconography, Inline, Stack } from '@customer-portal/affinity-components';
import { useTranslation } from 'react-i18next';
import { formatProductTitle, getProductImagePath } from '../../../../../theme/src/utils/product';
import { UpsellHeading } from './UpsellHeading';
import { UpsellProductCard } from './UpsellProductCard';
import { useClaimExperience } from './useClaimExperience';

/**
 * Offers List
 * Customer is presented with a list of offers.
 * Customer can claim an offer.
 * Offer is added to customer next delivery schedule.
 */
export const OffersList = ({
  experience,
  product,
  store,
}: {
  experience: ExperienceDiscountAddItem;
  product: Product;
  store: StoreData;
}) => {
  const { t } = useTranslation();
  const { handleClaimExperience, isLoading } = useClaimExperience();

  const availableVariants = experience.offer_data.product_variants[product.external_product_id].variant_ids;
  const variants = product.variants.filter(variant => availableVariants.includes(variant.shopify_id));
  const variant = variants[0];
  const image = getProductImagePath(product, variant.title) || experience.metadata?.image?.value;
  return (
    <Box className={experience.metadata.class}>
      <Card>
        <Stack space="xlarge">
          <UpsellHeading metadata={experience.metadata} />
          <Inline align="between" alignY="center">
            <Stack space="xlarge">
              <UpsellProductCard
                image={image}
                selectedProduct={product}
                selectedVariant={variant}
                currency={store.store.currency}
                discountPercent={experience.metadata.percent_value}
              />
            </Stack>

            <Box>
              <Button
                onPress={() =>
                  handleClaimExperience(
                    experience.offer_data.claim_id || 0,
                    variant.shopify_id,
                    formatProductTitle(product.title)
                  )
                }
                isLoading={isLoading}
              >
                <Icon>
                  <Iconography.PlusIcon />
                </Icon>
                {t('general_actions_add_product')}
              </Button>
            </Box>
          </Inline>
        </Stack>
      </Card>
    </Box>
  );
};
