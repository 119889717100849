import type * as Payloads from '~/types/requests';
import type { ApiContext } from '~/types/strategies';

import { post } from '~/common/rest';

/**
 * Delays the passed in prepaid order
 */
export async function delayPrepaidOrder(context: ApiContext, { orderId }: Payloads.DelayPrepaidOrderPayload) {
  const endpointUrl = context.urlGenerator(context.baseUrl, context.token, `/orders/${orderId}/delay`);

  await post(endpointUrl);
}
