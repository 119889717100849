import { GetChargesPayload } from '@customer-portal/affinity-api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from './useApi';

export const CHARGES_QUERY_KEY = 'charges';

export const useGetCharges = (params: GetChargesPayload = {}) => {
  const { api } = useApi();

  return useQuery([CHARGES_QUERY_KEY, params], () => api.getCharges(params));
};

export const useInvalidateGetCharges = () => {
  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries([CHARGES_QUERY_KEY]);

  return {
    invalidate,
  };
};
