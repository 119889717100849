import { z } from 'zod';
import { IsoDatetimeStringModel } from '~/models/commonModels';

export const CreditAccountModel = z.object({
  id: z.number(),
  customer_id: z.number(),
  available_balance: z.string(),
  created_at: IsoDatetimeStringModel,
  currency_code: z.string(),
  expires_at: IsoDatetimeStringModel.nullable(),
  name: z.string(),
  redemption_code: z.string(),
  updated_at: IsoDatetimeStringModel,
  api_client_id: z.number(),
  api_client_restricted: z.boolean(),
});
