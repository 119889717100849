interface UrlData {
  baseName: string;
  customerHash: string | null;
  token: string | null;
}

/**
 * Get the customer hash from the app proxy URL.
 *
 * The app proxy URL can have two formats:
 *  - With locale: /:locale/tools/:app_proxy_route/portal/:customer_hash/:extra_paths
 *  - Without locale: /tools/:app_proxy_route/portal/:customer_hash/:extra_paths
 *
 * The information of the customer hash is always located 3 paths ahead of the
 * /tools/ path.
 */
function getCustomerHashAppProxy(paths: string[]): string {
  const toolsIndex = paths.indexOf('tools');
  return paths[toolsIndex + 3];
}

/**
 * Get the basename from the app proxy URL.
 *
 * The app proxy URL can have two formats:
 *  - With locale: /:locale/tools/:app_proxy_route/portal/:customer_hash/:extra_paths
 *  - Without locale: /tools/:app_proxy_route/portal/:customer_hash/:extra_paths
 *
 * We want to get all the paths except the extra_paths, there are at most
 * 3 paths ahead of the /tools/ path.
 */
function getBasenameAppProxy(subpaths: string[]): string {
  const toolsIndex = subpaths.indexOf('tools');
  // The end argument in slice is exclusive, we have to add 1 to the number
  // of paths we want to obtain.
  return '/' + subpaths.slice(0, toolsIndex + 4).join('/');
}

type PartialLocation = Pick<Location, 'pathname' | 'search'>;

export function getUrlData(location: PartialLocation = window.location): UrlData {
  let baseName = '/';
  let customerHash = null;
  const { pathname, search } = location;

  const subpaths = pathname.split('/').filter(Boolean);
  const token = new URLSearchParams(search).get('token');

  if (pathname.includes('/tools')) {
    customerHash = getCustomerHashAppProxy(subpaths);
    baseName = getBasenameAppProxy(subpaths);
  } else if (pathname.startsWith('/portal')) {
    // /portal/:customer_hash
    customerHash = subpaths[1];
    baseName = '/' + subpaths.slice(0, 2).join('/');
  }

  return { baseName, customerHash, token };
}

/**
 * Removes prefix and customer hash from the pathname
 */
export const formatPathName = (pathname: string) =>
  pathname
    .replace(/^(\/tools\/recurring)?\/portal\/([\w]*[0-9]+[\w]*)/, '') // remove 'prefix & customer hash'
    .replace(/\/$/, ''); // remove '/' at the end
