import type { PagePagination } from '~/types/common';
import type { Plan, Product } from '~/types/responses';
import type { ShopifyThemeEngineProduct } from '~/types/api/response/products';
import { ThemeEnginePlan } from '~/types/api/response/plans';

function appendThemeId(searchParams: URLSearchParams) {
  const existingQueryParams = new URLSearchParams(location.search);
  const previewStandardTheme = existingQueryParams.get('preview_standard_theme');

  if (previewStandardTheme) {
    searchParams.set('preview_standard_theme', previewStandardTheme);
  }
}

export function sortQueryParams(queryParams: Record<string, string>): [string, string][] {
  const queryParamPairs = Object.entries(queryParams);

  // Sort parameters alphabetically
  queryParamPairs.sort(([paramA], [paramB]) =>
    String(paramA.toLocaleLowerCase()).localeCompare(paramB.toLocaleLowerCase())
  );

  return queryParamPairs;
}

export function generateQueryParams(params: Record<string, string>): URLSearchParams {
  const sortedParamPairs = sortQueryParams(params);

  const urlSearchParams = new URLSearchParams(sortedParamPairs);
  appendThemeId(urlSearchParams);

  return urlSearchParams;
}

export function generateRequestUrl(
  baseUrl: string,
  token: string,
  _subPath = '',
  params: Record<string, string> = {}
): string {
  const completeQueryParams = generateQueryParams({ ...params, token });
  const subPath = _subPath.startsWith('/') ? _subPath.substring(1) : _subPath;

  let requestUrl = `${baseUrl}/${subPath}`;
  if (Array.from(completeQueryParams).length > 0) {
    requestUrl = `${requestUrl}?${completeQueryParams.toString()}`;
  }
  return requestUrl;
}

/**
 * Theme Engine may return paginated values without the "meta" attribute.
 * To handle this kind of pagination, we're using some heuristics based on the response items,
 * and the received filters.
 * @param filters - The filters passed by the user.
 * @param items - The received items from the Theme Engine response.
 * @returns A boolean indicating if there may be another page.
 */
export function hasNextPageMetaless<Filters extends Partial<PagePagination>>(filters: Filters, items: unknown[]) {
  // If there are no items returned, we can be sure that there is not another page.
  if (items.length === 0) return false;

  // If there's a limit, check if the number of items is similar to it, there may be another page
  const limitValue = filters.limit || 250; // 250 is the default limit
  return items.length >= limitValue;
}

/**
 * Theme Engine may return paginated values without the "meta" attribute.
 * To handle this kind of pagination, we're using some heuristics based on the response items,
 * and the received filters.
 * @param filters - The filters passed by the user.
 * @returns A boolean indicating if there may be a previous page.
 */
export function hasPreviousPageMetaless<Filters extends Partial<PagePagination>>(filters: Filters) {
  return filters.page && filters.page > 1;
}

export function generateNextPageFilters<F extends Partial<PagePagination>>(filters: F): F & { page: number } {
  const nextPage = Math.min((filters.page ?? 1) + 1, 2_000_000);
  return { ...filters, page: nextPage };
}

export function generatePreviousPageFilters<F extends Partial<PagePagination>>(filters: F): F & { page: number } {
  const previousPage = Math.max((filters.page ?? 1) - 1, 1);
  return { ...filters, page: previousPage };
}

export function ensureArray<T extends object>(record: T | T[]): T[] {
  // Do nothing if it's already an array
  if (Array.isArray(record)) return record;

  // Return an empty array if it's an empty object
  if (Object.keys(record).length === 0) return [];

  return [record];
}

type PlanMapFn = (product: ShopifyThemeEngineProduct) => Plan[];
type ProductWithPlan = ShopifyThemeEngineProduct & { plans?: ThemeEnginePlan[] };
export function normalizeProduct(product: ProductWithPlan, planMap: PlanMapFn): Product {
  return {
    id: product.id,
    collection_ids: product.collection_ids,
    discount_amount: product.discount_amount,
    discount_type: product.discount_type,
    external_product_id: product.shopify_details.shopify_id,
    handle: product.handle,
    images: product.images,
    options: product.shopify_details.options,
    plans: planMap(product),
    subscription_options: product.subscription_defaults,
    tags: product.shopify_details.tags,
    title: product.title,
    variants: product.shopify_details.variants,
    bundle_product: product.bundle_product,
  };
}

type SubscriptionProduct = Pick<
  ShopifyThemeEngineProduct,
  'subscription_defaults' | 'discount_type' | 'discount_amount'
>;
function generateGenericPlan(product: SubscriptionProduct, type: 'onetime', index: number, frequency?: null): Plan;
// prettier-ignore
function generateGenericPlan(product: SubscriptionProduct, type: Exclude<Plan['type'], 'onetime'>, index: number, frequency: string): Plan;
// prettier-ignore
function generateGenericPlan(product: SubscriptionProduct, type: Plan['type'], index: number, frequency: string | null = null): Plan {
  const title = frequency ? `Every ${frequency} ${product.subscription_defaults.order_interval_unit}` : 'Onetime';
  const safeFrequency = Number.isInteger(Number(frequency)) ? Number(frequency) : null;

  return {
    type,
    title,
    id: `${frequency}-${product.subscription_defaults.order_interval_unit}`,
    sortOrder: index,
    discountType: product.discount_type,
    discountAmount: String(product.discount_amount),
    channelSettings: {
      display: product.subscription_defaults.storefront_purchase_options !== 'inactive',
    },
    subscriptionPreferences: {
      chargeIntervalFrequency:
        type === 'prepaid' ? product.subscription_defaults.charge_interval_frequency : safeFrequency,
      intervalUnit: product.subscription_defaults.order_interval_unit,
      orderDayOfWeek: product.subscription_defaults.order_day_of_week,
      expireAfterSpecificNumberOfCharges: product.subscription_defaults.expire_after_specific_number_of_charges,
      cutoffDayOfMonth: product.subscription_defaults.cutoff_day_of_month,
      cutoffDayOfWeek: product.subscription_defaults.cutoff_day_of_week,
      orderIntervalFrequency: safeFrequency,
      orderDayOfMonth: product.subscription_defaults.order_day_of_month,
    },
    hasVariantRestrictions: false,
    externalVariantIds: [],
  };
}

export function mapSubscriptionProductToPlans(product: SubscriptionProduct): Plan[] {
  const subscriptions = product.subscription_defaults.order_interval_frequency_options.map<Plan>(
    (frequency, index, frequencies) => {
      const hasDifferentFreq = Number(product.subscription_defaults.charge_interval_frequency) !== Number(frequency);
      const subscriptionType = frequencies.length === 1 && hasDifferentFreq ? 'prepaid' : 'subscription';
      return generateGenericPlan(product, subscriptionType, index + 1, frequency);
    }
  );

  if (product.subscription_defaults.storefront_purchase_options.includes('onetime')) {
    return [generateGenericPlan(product, 'onetime', 0), ...subscriptions];
  }

  return subscriptions;
}

export function mapPlan(product: ProductWithPlan & SubscriptionProduct): Plan[] {
  // This is a temporal fix for the double write of plans in stores that have not activated the PSP feature yet.
  // When the product is not added to Recharge, the plans are not written to the DB. We must return a generic
  // onetime plan in this case.
  if (Array.isArray(product.plans) && product.plans.length === 0) {
    return [generateGenericPlan(product, 'onetime', 0)];
  }

  return (product.plans || [])
    .map(plan => ({
      type: plan.type,
      title: plan.title,
      id: plan.id.toString(),
      sortOrder: plan.sort_order,
      discountType: plan.discount_type,
      discountAmount: plan.discount_amount,
      channelSettings: {
        display: plan.channel_settings.customer_portal.display,
      },
      subscriptionPreferences: {
        chargeIntervalFrequency: plan.subscription_preferences.charge_interval_frequency,
        intervalUnit: plan.subscription_preferences.interval_unit,
        orderDayOfWeek: plan.subscription_preferences.order_day_of_week,
        expireAfterSpecificNumberOfCharges: plan.subscription_preferences.expire_after_specific_number_of_charges,
        cutoffDayOfMonth: plan.subscription_preferences.cutoff_day_of_month,
        cutoffDayOfWeek: plan.subscription_preferences.cutoff_day_of_week,
        orderIntervalFrequency: plan.subscription_preferences.order_interval_frequency,
        orderDayOfMonth: plan.subscription_preferences.order_day_of_month,
      },
      hasVariantRestrictions: plan.has_variant_restrictions,
      externalVariantIds: plan.external_variant_ids,
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder);
}
